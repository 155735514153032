import { useState, useEffect, useCallback, useMemo, createRef } from 'react';
import { useLocation, Outlet } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { NotificationBridge } from '../notifications';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { observer } from 'mobx-react-lite';
import Header from './Header';
import { account, algorand, globalAppState, localAppState, assets } from '../state/index.js';
import { refreshBalances } from '../algo.js';
import Preload from '../images/cupstakes-preload.js';
import useScroll from '../hooks/scroll.js';
import useInterval from '../hooks/use-interval.js';
import NetWatch from './algo-net-watch.js';
import throttle from 'lodash/throttle';
import FontFaceObserver from 'fontfaceobserver';
import MuiLink from '@mui/material/Link';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { HashLink as RouterLink } from 'react-router-hash-link';
import PauseWatcher from './pause-watcher.js';
import Socials from './Socials.jsx';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TwitterIcon from '@mui/icons-material/Twitter';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ForumIcon from '@mui/icons-material/Forum';
import LanguageIcon from '@mui/icons-material/Language';

export function Link({ href, children, ...props }) {
  const component = href.startsWith('http') || href.startsWith('mailto') ? { target: "_blank", href: href } : { component: RouterLink, to: href };
 return <MuiLink {...component} {...props}>{children}</MuiLink>
}


export function Left({ children, sx, ...props }) {
  return <VFlex sx={{alignItems: 'flex-start', ...sx}} {...props}>{children}</VFlex>;
}

function setStyleProperty(key, value) {
  document.documentElement.style.setProperty(key, value);
}

const setStylePropertyThrottled = throttle(setStyleProperty, 33, { leading: true });

function Scroll() {
}

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    const path = location.pathname + location.search + location.hash;
    try{
      window.goatcounter?.count({ path });
    } catch(e) {
      console.log("goatcounter count error", e.message);
    }
  }, [location]);
}

function useLoading() {
  //  TODO font loader for load
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      setTimeout(() => setLoaded(true), 20_000);
      const observers = [
        new FontFaceObserver('Maldini'),
        new FontFaceObserver('MaldiniStyle'),
        new FontFaceObserver('MaldiniBold'),
        new FontFaceObserver('Roboto'),
      ];
      try {
        await Promise.all(observers.map(o => o.load()));
      } catch(e) {
      }
      setLoaded(true);
    })()
  }, []);
  
  return [loaded];
}

export function BigLoader() {
  return <div className="loader-5 center"><span></span></div>;
}

export function Logo() {
  return <>
    <div className="center-fade"></div>
    <div className="background-logo"></div>
    <div className="radial-background-container">
      <div className="radial-background"></div>
    </div>
  </>;
}

export function HFlex({children, hide, ...props}) {
  let { sx, ...restProps } = props;
  if (props.grow)
    sx = { ...sx, flexGrow: 1 };
  return <Box {...restProps} sx={{display: hide ? 'none' : 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', ...sx}}>{children}</Box>
}

export function VFlex({children, ...props}) {
  let { sx, ...restProps } = props;
  if (props.grow)
    sx = { ...sx, flexGrow: 1 };
  return <HFlex {...restProps} sx={{flexDirection: 'column', ...sx}}>{children}</HFlex>
}

export function VSeparator({ vh, sx, ...props}) {
  const finalSX = { flexGrow: 1, minHeight: '3vh', ...sx };
  if (vh) {
    finalSX.minHeight = typeof vh === "object" ? vh : `${vh}vh`;
  }
  return <VFlex {...props} sx={finalSX}></VFlex>
}

export function Opaque({ vh, sx, h1, h2, op = 0.9, ph = {xs: 2, md: 5}, children, ...props }) {
  let finalSX = { pt: 2, pb: 3, background: `rgba(255,255,255,${op})` };
  if (h1)
    finalSX.pb = 0;
  if (h2)
    finalSX.pb = 1;

  if (ph) {
    finalSX.pl = ph;
    finalSX.pr = ph;
  }
  if (vh) {
    finalSX.minHeight = typeof vh === "object" ? vh : `${vh}vh`;
  }
  if (sx) {
    finalSX = { ...finalSX, ...sx };
  }
  return <VFlex className="opaque" {...props} sx={finalSX}>
    {children}
  </VFlex>
}

function ButtonToTop() {
  const scrollToTop = useCallback(() => {
    window.scroll(0, 0);
  }, []);
  return <Box sx={{position: 'fixed', left: 5, bottom: 8, fontSize: '4pt', zIndex: 100}}>
    <IconButton color="primary" sx={{ border: '1px var(--primary) solid' }} size="small" onClick={scrollToTop}>
      <ArrowUpwardIcon fontSize="inherit" />
    </IconButton>
  </Box>;
}

export default function () {
  usePageViews();
  const [loaded] = useLoading();
  const [showingToTop, setShowingToTop] = useState(false);
  const scrollPosition = useScroll();
  const location = useLocation();

  const notistackRef = createRef();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const showFooter = useMemo(() => {
    return location.pathname !== '/';
  }, [location.pathname]);

  useEffect(() => {
    const deg = scrollPosition / 20 % 360;
    if (scrollPosition > 150 && !showingToTop) {
      setShowingToTop(true);
    } else if (scrollPosition <= 150 && showingToTop) {
      setShowingToTop(false);
    }
    setStylePropertyThrottled('--rotate', `${deg}deg`);
  }, [scrollPosition, showingToTop]);

  return <SnackbarProvider
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    ref={notistackRef}
    action={(key) => (
      <IconButton onClick={onClickDismiss(key)} color="inherit">
        <CloseIcon />
      </IconButton>
    )}
  >
    <NotificationBridge />
    { loaded ? (<>
      <Header account={account} />
      <Container className="layout"
        disableGutters
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: { xl: 0.7, lg: 0.8, md: 1.0, sm: 1, xs: 1 },
        }}>
        <Outlet />
      </Container>
      { showFooter ? <Socials mini={true} /> : null }
      <Preload />
      <Logo />
      { showingToTop ? <ButtonToTop /> : null }
    </>) : <Container className="layout"
      disableGutters
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: { xl: 0.7, lg: 0.8, md: 1.0, sm: 1, xs: 1 },
      }}>
      <Logo />
      <BigLoader />
    </Container> }
  </SnackbarProvider>;

};

export function TeamHolder({ children, left, right, top=25 }) {
  const mr = left ? { mr: 0 } : { ml: 0 };
  const up = `translate(0%, -${top}%)`;
  const hideRight = right ? { md: 'none', lg: 'flex', } : {} ;
  return <Box sx={{display: { xs: 'none', md: 'flex', ...hideRight }, m: -20, ...mr, transform: { md: `${up} scale(0.6)`, lg: `${up} scale(0.75)` } }}>
    {children}
  </Box>;
}

export function Thanks() {
  return <Opaque sx={{width: 1}}>
    <Typography variant="h3">Thank You!</Typography>
    <Left sx={{mt: 1}}>
      <Typography>Thank you for playing with us.</Typography>
      <Typography>The engagement and excitement we got from you made this all worth it.</Typography>
      <Typography>- The D13.co//ective</Typography>
    </Left>
    <HFlex sx={{mt: 2}}>
      <Button variant="outlined" startIcon={<TwitterIcon />} onClick={() => window.open('https://twitter.com/d13_co')}>@D13_CO</Button>
      <Button variant="outlined" sx={{ml: 1}} startIcon={<LanguageIcon />} onClick={() => window.open('https://d13.co')}>D13.CO</Button>
      <Button variant="outlined" sx={{ml: 1}} startIcon={<ForumIcon />} onClick={() => window.open('https://discord.com/invite/Hf92HpbSJU')}>D13..SCORD</Button>
    </HFlex>
  </Opaque>
}
