import { useMemo, useState, useEffect, useCallback } from 'react';
import Tooltip from '../components/Tooltip.jsx';
import { ALGO, numLocaleOptionsFull, numLocaleOptions } from '../constants.js';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, VSeparator, Opaque, HFlex, VFlex, BigLoader, Left, } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { sendCollectTxns, sendDraw1Txns } from '../algo.js';
import { account, history, assets, localAssets, localAppState, globalAppState } from '../state/';
import Play from '../components/Play.jsx';
import NFTs from '../components/NFTs.jsx';
import AllNFTTable from '../components/AllNFTTable.jsx';
import AllNFTs from '../components/AllNFTs.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TxnAddress, TxnId } from '../components/History.jsx';
import { appParamUpdates, freeDrawOffsets } from '../algo/indexer.js';
import { TableRow as RTableRow, TableCell as RTableCell } from '../components/responsive-table.js'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cp-data-tabpanel-${index}`}
      aria-labelledby={`cp-data-tab-${index}`}
      {...other}
    >
      {value === index && (<>
          {children}
      </>)}
    </div>
  );
}

function a11yProps(_id) {
  const { id } = tabs.find(({id}) => id === _id);
  return {
    id,
    'aria-controls': `cp-tabpanel-${id}`,
  };
}

const tabs = [
  { id: "odds", title: "Odds & Parameters", route: "/audit/#odds", },
  { id: "freedraws", title: "Free Draws", route: "/audit/#freedraws", },
]

const algoFields = ['ticket', 'burnTicket', 'ticketDisplay'];
const timeFields = ['pausingAt'];

export const MiniTeam = observer(({assets, id, odds, cumOdds}) => {
  const [child, setChild] = useState(<>{(odds * 100).toFixed(3)}%</>);

  const { flag, name, rankLabel, rank, available } = assets.get(id) ?? {};

  const tooltip = useMemo(() => <>
    {name}<br />
    {id}<br/>
    Odds: {(100*odds).toFixed(5)}%<br />
    C. Odds: {cumOdds}
  </>, [id, odds]);

  const setExpandChild=useCallback(() => {
    setChild(tooltip);
  }, [id, odds]);

  return <VFlex sx={{mr: 1}}>
    <Tooltip icon={<span onClick={setExpandChild} className="bigflag">{flag}</span>}>
      {tooltip}<br />
      (Click me)
    </Tooltip>
    <Typography sx={{fontSize: '80%'}}>
      {child}
    </Typography>
  </VFlex>
});

const FreeDrawAudit = observer(({ history }) => {
  const [prices, setPrices] = useState([]);
  const [freeDraws, setFreeDraws] = useState([]);
  const [totalSpend, setTotalSpend] = useState(0);
  const [offsets, setOffsets] = useState([]);
  const [totalOffsets, setTotalOffsets] = useState(0);

  useEffect(() => {
    (async () => {
      const { mainAppUpdates } = await appParamUpdates();
      const prices = [];
      let lastRound = 0;
      let lastPrice = 0;
      let r;
      for(const [round, params] of Object.entries(mainAppUpdates)) {
        const { ticket } = params;
        if (ticket && ticket !== lastPrice) {
          prices.push([lastRound, Number(round), lastPrice]);
          lastPrice = ticket;
          lastRound = Number(round);
        }
        r = Number(round);
      }
      prices.push([r, Infinity, lastPrice]);
      setPrices(prices);
    })()
  }, []);

  useEffect(() => {
    (async() => {
      const d = await freeDrawOffsets();
      setOffsets(d);
      setTotalOffsets(d.reduce((sum, {amount}) => sum + amount, 0));
    })()
  }, []);

  const priceAtRound = useCallback(r =>
    prices.length ?
      prices.find(([from, to]) => 
        from < r && r < to
      )[2]
    : 0
  , [prices]);

  useEffect(() => {
    if (prices.length === 0) {
      return;
    }
    let total = 0;
    const fds = [];
    for(const entry of history.data) {
      if (entry?.method == 'free_draw') {
        const price = priceAtRound(entry.r);
        fds.push({
          ...entry,
          price,
        });
        total += price;
      }
    }
    setFreeDraws(fds);
    setTotalSpend(total);
  }, [priceAtRound, history.lastRound]);

  return <>
   <Opaque  sx={{minWidth: 1}}>
     <Typography variant="h3" sx={{mb: 1}}>Free Draws Audit</Typography>
     <Left sx={{fontSize: '1.1rem'}}>
       <span>This section provides an audit of Free Draws.</span>
       <span>We contributed each Free Draw's ALGO equivalent into the rewards pool.</span>
       <span><strong>Payments</strong> shows our payments into the rewards pool.</span>
       <span><strong>Plays</strong> shows the cost of a draw when players redeemed them.</span>
     </Left>
   </Opaque>
   <VSeparator />
   <Opaque vh={25} sx={{minWidth: 1}}>
   { !offsets.length ? <VFlex>LOADING</VFlex> : 
   <>
     <Typography variant="h3" sx={{mb: 1}}>Free Draw Payments</Typography>
     <strong>Total: {ALGO}{(totalOffsets/1000000).toLocaleString(undefined, numLocaleOptions)}</strong>
   <Table size="small">
     <TableHead>
       <TableRow>
         <TableCell>
           Txn
         </TableCell>
         <TableCell>
           Date/Time
         </TableCell>
         <TableCell>
           ALGO Value
         </TableCell>
       </TableRow>
     </TableHead>
     <TableBody>
       { offsets.map(({rT, id, address, amount: price}, i) => <TableRow key={i}>
           <TableCell>
             { id ? <TxnId id={id} /> : null }
           </TableCell>
           <TableCell>
             {new Date(rT*1000).toLocaleString()}
           </TableCell>
           <TableCell>
             {ALGO}{(price/1000000).toLocaleString()}
           </TableCell>
         </TableRow>
       ) }
     </TableBody>
   </Table></> }
 </Opaque>
   <VSeparator />
   <Opaque vh={25} sx={{minWidth: 1}}>
   { !freeDraws.length ? <VFlex>LOADING</VFlex> : 
   <>
     <Typography variant="h3" sx={{mb: 1}}>Free Draw Plays</Typography>
     <strong>Total: {ALGO}{(totalSpend/1000000).toLocaleString(undefined, numLocaleOptions)}</strong>
   <Table size="small">
     <TableHead>
       <TableRow>
         <TableCell>
           Txn
         </TableCell>
         <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
           Date/Time
         </TableCell>
         <TableCell>
           Player
         </TableCell>
         <TableCell>
           ALGO Value
         </TableCell>
       </TableRow>
     </TableHead>
     <TableBody>
       { freeDraws.map(({rT, id, address, price}, i) => <TableRow key={i}>
           <TableCell>
             { id ? <TxnId id={id} /> : null }
           </TableCell>
           <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
             {new Date(rT*1000).toLocaleString()}
           </TableCell>
           <TableCell>
             <TxnAddress address={address} />
           </TableCell>
           <TableCell>
             {ALGO}{(price/1000000).toLocaleString()}
           </TableCell>
         </TableRow>
       ) }
     </TableBody>
   </Table></> }
 </Opaque>
</>;
});

function AppAudit() {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const { mainAppUpdates, oddsUpdates, roundTimes } = await appParamUpdates();
      const data = [];
        for(const [round, { odds, gid }] of Object.entries(oddsUpdates)) {
          data.push({
            type: 'Odds Change',
            round,
            gid,
            ts: new Date(roundTimes[round] * 1000),
            value: <HFlex>{odds.map(([id, cumOdds, odds,]) => {
              return <MiniTeam assets={assets} id={id} odds={odds} cumOdds={cumOdds} />;
              })}</HFlex>,
          });
        }
        for(const [round, changes] of Object.entries(mainAppUpdates)) {
          data.push({
            type: 'Parameter Change',
            round,
            id: changes.id,
            ts: new Date(roundTimes[round] * 1000),
            value: <VFlex>{Object.entries(changes).map(([key, value]) => {
              if (key === "id") {
                return null;
              }
              if (key === 'ticket' && value === 0)
                value = 'Ⱥ0 (Drawing Disabled)';
              else if (algoFields.includes(key))
                value = 'Ⱥ' + (value / 1_000_000).toLocaleString(undefined, numLocaleOptionsFull);
              if (timeFields.includes(key))
                if (value)
                  value = new Date(value * 1000).toLocaleString();
              else
              value = '0 (Drawing Resumed)';
              return <span>{caseToReadable(key)}={value}</span>;
            }).filter(Boolean)}</VFlex>,
          });
        }
      data.sort((a, b) => a.round < b.round ? -1 : 1);
      setData(data);
    })()
  }, []);

  return <>
    <Opaque  sx={{minWidth: 1}}>
      <Typography variant="h3" sx={{mb: 1}}>Odds & Parameters Updates</Typography>
      <Left sx={{fontSize: '1.1rem'}}>
        <Typography sx={{ mb: 2, display: { xs: 'block', md: 'none' } }}><em>This page isn't optimized for mobile. Sorry!</em></Typography>
        <span>Here you can see all updates to the Draw smart contract parameters.</span>
        <span>This section includes contract parameters like Ticket price, as well as Team odds.</span>
        <span>You can refer to the <Link href="https://github.com/CupStakes/cupstakes-smart-contracts/#draw-contract-global">Smart Contract documentation</Link> on GitHub for more details.</span>
        <hr />
        <Table size='small' sx={{mb: 2}}>
          <TableBody>
            <TableRow>
              <TableCell><strong>Ticket</strong></TableCell>
              <TableCell className="tl">Full Draw ticket price; disables drawing at contract level when zero.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Burn_Ticket</strong></TableCell>
              <TableCell className="tl">Burn & Re-Draw ticket price.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Ticket_Display</strong></TableCell>
              <TableCell className="tl">Used by the frontend to display the ticket price whioe drawing is disabled.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Pausing_At</strong></TableCell>
              <TableCell className="tl">Used by the frontend to disable drawing when an elimination match starts.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <span>You can hover or click on a Team flag to expand more details.</span>
      </Left>

    </Opaque>
    <VSeparator />
    <Opaque vh={65} sx={{minWidth: 1}}>
    { !data.length ? <VFlex>LOADING</VFlex> : 
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            Txn
          </TableCell>
          <TableCell>
            Date/Time
          </TableCell>
          <TableCell>
            Values Changed
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { data.map(({ts, id, gid, round, type, value}, i) => <TableRow key={i}>
            <TableCell>
              { id ? <TxnId id={id} /> : null }
              { gid ? <TxnId round={round} gid={gid} /> : null }
            </TableCell>
            <TableCell>
              {ts.toLocaleString()}
            </TableCell>
            <TableCell>
              {value}
            </TableCell>
          </TableRow>
        ) }
      </TableBody>
    </Table> }
  </Opaque>
  </>;
}

function caseToReadable(s) {
  return s[0].toUpperCase() + s.slice(1).replace(/[A-Z]/g, (l) => '_'+l);
}

function Data({ }) {
  const location = useLocation();
  const navigate = useNavigate();

  const value = useMemo(() => {
    const def = 0;
    const { hash } = location;
    if (hash && hash.startsWith('#')) {
      const idx = tabs.findIndex(tab => tab.route.endsWith(hash));
      return idx === -1  ? def : idx;
    }
    return def;
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    const { route } = tabs[newValue];
    navigate(route);
  };

  return <>
    <VSeparator id="data" />

    <Opaque>
      <Typography align="center" id="#table" variant="h2">AUDIT</Typography>
      <HFlex>
        <Tabs
          value={value} 
          TabIndicatorProps={{ sx: { display: 'none' } }}
          onChange={handleChange}
          aria-label="Data navigation" sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
          },
          }}>
          {tabs.map(({ id, title, }) =>
            <Tab key={`tabhead-${id}`} label={title} {...a11yProps(id)} />
          )}
        </Tabs>
      </HFlex>
    </Opaque>

    <VSeparator />

    <TabPanel value={value} index={0}>
      <VFlex>
        <AppAudit />
      </VFlex>
    </TabPanel>

    <TabPanel value={value} index={1}>
      <VFlex>
        <FreeDrawAudit history={history} />
      </VFlex>
    </TabPanel>

    <VSeparator />
  </>
}

/* Your History */
/* Leaderboard */
/* All Actions */
/* Odds */
/* Analytics */

export default observer(Data);

