import { useState, useEffect, useCallback } from 'react';
import { VFlex, Link } from './Layout.jsx';
import notifications from '../notifications.js';
import { observer } from 'mobx-react-lite';
import { ALGO } from '../constants.js';
import { refreshBalances } from '../algo.js';
import useInterval from '../hooks/use-interval.js';
import Typography from '@mui/material/Typography';
import { numLocaleOptions } from '../constants.js';

const minutesThreshold = 30;

export default observer(function({globalAppState}) {
  const { pausingAt } = globalAppState;
  const [isPaused, setIsPaused] = useState(false);
  const [isPausingSoon, setIsPausingSoon] = useState(false);
  const updateIsPaused = () => {
    const now = Math.floor(Date.now() / 1000);
    // console.log("checking paused", now, pausingAt);
    if (!isPaused && pausingAt) {
      const minutes = Math.floor((pausingAt - now)/60);
      if (true) {
        // console.log("setting paused", pausingAt, now);
        setIsPaused(true);
        setIsPausingSoon(false);
        globalAppState.setState({isPaused: true});
        notifications.warning(<VFlex sx={{alignItems: 'flex-start'}}>
          <Typography sx={{alignSelf: 'center'}}><strong>CupStakes Drawing is finished!</strong></Typography>
          <Typography>CupStakes drawing was concluded before the first semi-final match started.</Typography>
          <Typography>If you joined us and spent your ALGO on CupStakes:</Typography>
          <Typography>Vote on what to do with unclaimed rewards in our <Link href="/gov">Governance page!</Link></Typography>
          <Typography>Stay tuned for Trust partner audit results and distribution dates (December 19 - 23)</Typography>
          <Typography>Thanks for playing!</Typography>
        </VFlex>, 0, 'pause');
      } else if (!isPausingSoon && minutes <= minutesThreshold) { // pausing within 1 hr
        // console.log("setting pausing soon");
        setIsPausingSoon(true);
        globalAppState.setState({pausingSoon: true});
        notifications.warning(<VFlex sx={{alignItems: 'flex-start'}}>
          <Typography sx={{alignSelf: 'center'}}><strong>Drawing will be paused soon!</strong></Typography>
          <Typography>An Elimination match is starting {minutes ? `in ${minutes} minute${minutes > 1 ? 's' : ''}` : 'now'}.</Typography>
          <Typography>During the match, CupStakes will be paused.</Typography>
          <Typography>We will be back with updated odds and an increased ticket price.</Typography>
        </VFlex>, 30_000 ,'pause');
      }
    } else if (isPaused) { // isPaused
      if (!pausingAt) {
        //console.log('unpausing');
        setIsPaused(false);
        setIsPausingSoon(false);
        globalAppState.setState({isPaused: false});
        notifications.info(<VFlex>
          <Typography><strong>Drawing is enabled again!</strong></Typography>
          <Typography>Ticket price is {ALGO}{globalAppState.ticketAlgo.toLocaleString(undefined, numLocaleOptions)}</Typography>
        </VFlex>, 30_000 ,'pause');
      }
    }
  };
  const updateIsPausedInt = async () => {
    await refreshBalances();
    updateIsPaused();
  }
  useEffect(() => {
    updateIsPaused();
  }, [pausingAt]);
  useInterval(updateIsPausedInt, 60 * 1_000);
  return null;
});
