import { useState, useCallback, useEffect, useMemo } from 'react';
import useInterval from '../hooks/use-interval.js';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { observer } from 'mobx-react-lite';
import { TableRow as RTableRow, TableCell as RTableCell } from '../components/responsive-table.js'
import { ALGO, numLocaleOptionsFull } from '../constants.js';
import { shorten, lookupNFD } from '../utils.js';
import { Link, VSeparator, Opaque, HFlex, VFlex, BigLoader } from '../components/Layout';
import { assets, account, fsm, globalAppState } from '../state/';
import Tooltip from '../components/Tooltip.jsx';
import Wallet from './Wallets.jsx';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import relativeDate from '../relative-date.js';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const hideActions = ['closeout', 'clearout', 'exec_draw'];
const hideActionsGlobal = [...hideActions, 'collect'];
const hidePersonalActions = [...hideActions, 'optin'];

function RightAlignTableCell({ children, sx, ...props }) {
  const osx = { ...sx, textAlign: 'right !important' };
  return <TableCell {...props} sx={osx}>{children}</TableCell>
}

// TODO Active players: unique optins with draw
// TODO fix reusing/resuming indexer state
// TODO refresh indexer button?
const Stats = observer(({ stats, methods, global, globalAppState, players, loading, teams }) => {
  const defaultValue = 'Loading';
  let { total, burns, draws, rate, spend, } = stats ?? { total: 0, burns: 0, rate: 0, spend: 0  };
  if (isNaN(rate))
    rate = 0;
  const { rewardsPoolAmount } = globalAppState ?? {};
  let actionFilter = hidePersonalActions;
  const avgDrawsPerPlayer = players ? (total / players).toLocaleString(undefined, { maximumFractionDigits: 1 }) : defaultValue;
  let extra;
  if (global) {
    actionFilter = hideActionsGlobal;
    extra = <>
      <TableRow>
        <TableCell><Link href="https://algoscan.app/address/BSJAMHBCLLSOBW4GAP2DWACN7B3VPEPSU6SCUTVBS3HRC7UZ35Z2KLVLF4">Rewards Pool</Link></TableCell> <RightAlignTableCell>{ALGO}{(rewardsPoolAmount/1000000).toLocaleString(undefined, numLocaleOptionsFull)}</RightAlignTableCell>
      </TableRow>
      <TableRow>
        <TableCell>Players</TableCell><RightAlignTableCell>{players}</RightAlignTableCell>
      </TableRow>
      <TableRow>
        <TableCell>Avg Draws per Player</TableCell><RightAlignTableCell>{avgDrawsPerPlayer}</RightAlignTableCell>
      </TableRow>
    </>
  }
  const mw = {
    xs: '380px',
    lg: '450px',
  }

  const actions = Object.entries(methods ?? {}).sort(([key1], [key2]) =>
    getPrettyDrawMethod(key1) < getPrettyDrawMethod(key2) ? -1 : 1
  ).filter(([name]) => !actionFilter.includes(name));

  const hasActions = actions.length;

  return <VFlex><HFlex sx={{flexDirection: { xs: 'column', md: 'row', }, width: 1, justifyContent: 'space-between' }}>
    <Opaque sx={{ minWidth: mw, alignSelf: { xs: 'center', md: 'stretch' }, mb: { xs: 2, md: 0 }, justifyContent: 'flex-start' }}>
      <Typography sx={{borderBottom: '1px var(--primary) solid', textAlign: 'center', width: 1, pb: 1 }} variant="h3">Stats</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Total Draws</TableCell> <RightAlignTableCell>{total}</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Burn Draws</TableCell> <RightAlignTableCell>{burns}</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Burn Rate</TableCell> <RightAlignTableCell>{rate}%</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Player Spend</TableCell> <RightAlignTableCell>{ALGO}{spend?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</RightAlignTableCell>
          </TableRow>
          { extra }
        </TableBody>
      </Table>
    </Opaque>
    <Opaque sx={{ minWidth: mw, alignSelf: { xs: 'center', md: 'stretch' }, justifyContent: 'flex-start' }}>
      <Typography sx={{ borderBottom: '1px var(--primary) solid', pb: 1, textAlign: 'center', width: 1 }} variant="h3">Actions</Typography>
      { !hasActions ? <VFlex sx={{flexGrow: 1}}>
        <Typography sx={{fontSize:'2rem'}}>{loading ? '🕰️' : '😶'}</Typography>
        </VFlex> : null}
      <Table>
        <TableBody>
          { actions.map(([name, num], i) => {
            return <TableRow key={`st-gjn-${i}`}>
              <TableCell>{getPrettyDrawMethod(name)}</TableCell>
              <TableCell sx={{textAlign: 'right'}}>{num}</TableCell>
            </TableRow>
          }) }
        </TableBody>
      </Table>
    </Opaque>
  </HFlex>
    { teams?.length ? 
      <>
        <VSeparator />
        <Opaque sx={{ minWidth: mw, alignSelf: { xs: 'center', md: 'stretch' }, mb: { xs: 2, md: 0 }, justifyContent: 'flex-start' }}>
          <Typography sx={{borderBottom: '1px var(--primary) solid', textAlign: 'center', width: 1, pb: 1, mb: 2 }} variant="h3">Collected Teams</Typography>
          <HFlex sx={{flexWrap: 'wrap'}}>
            { teams.map(([id, amount]) => <Badge sx={{mr: 2}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} badgeContent={amount} color="primary">
              <MiniTeam assets={assets} id={id} />
            </Badge>) }
          </HFlex>
        </Opaque></> : null }
    </VFlex>;
});


function HistoryEntry(props) {
  const { rT, method, address, d: delta, id } = props;

  switch(method) {
    case 'exec_draw': return <ExecDraw assets={assets} {...props} />
    case 'draw':
    case 'draw3': return <Draw {...props} />
    case 'burn_draw':
    case 'burn_draw2':
    case 'burn_draw3': return <BurnDraw {...props} />
    case 'collect': return <Collect {...props} />
    default: return <Misc {...props} />
  }
}

function getPrettyDrawMethod(method) {
  switch(method) {
    case 'collect': return 'Collect';
    case 'draw': return 'Draw x1';
    case 'draw3': return 'Draw x3';
    case 'free_draw': return 'Free Draw x1';
    case 'burn_draw': return 'Burn Draw x1';
    case 'burn_draw2': return 'Burn Draw x2';
    case 'burn_draw3': return 'Burn Draw x3';
    case 'exec_draw': return <em>Draw Executed</em>;
    case 'optin': return 'Application Opt-in';
    case 'optout': return 'Application Opt-out';
    default: return method;
  }
}

function BurnDraw(props) {
  const { d: delta, p } = props;
  const teams = Object.values(p);
  return <HistoryRow {...props}>
    <MiniTeams extra={<span>{ALGO}{delta['draw_amount_paid']/1_000_000}</span>} teams={teams} />
  </HistoryRow>;
}

function Draw(props) {
  const { d: delta } = props;
  return <HistoryRow {...props}>
      {ALGO}{delta['draw_amount_paid']/1_000_000}
  </HistoryRow>;
}

function MiniTeams({teams, extra}) {
  const children =  teams.map((d, i) => <MiniTeam key={`miniteam-${i}`} assets={assets} id={d} />);
  return <HFlex sx={{justifyContent: 'space-between', width: 0.8}}> 
    {extra}{children}
  </HFlex>
}

function Collect(props) {
  const { p } = props;
  const teams = Object.values(p);
  return <HistoryRow {...props} >
    <MiniTeams teams={teams} />
  </HistoryRow>
}


function Misc(props) {
  const { method } = props;
  if (method === "optin")
    return <HistoryRow {...props}><Typography sx={{fontSize: '2rem'}}>👋</Typography></HistoryRow>;
  return <HistoryRow {...props} />
}

function RoundTime({ t }) {
  // return <span>{new Date(t * 1000).toLocaleString()}</span>;
  return <span>{relativeDate(t*1000)}</span>;
}

export function TxnId({ id, gid, round, label, sx }) {
  const href = useMemo(() => id ? `https://algoexplorer.io/tx/${id}` : `https://algoexplorer.io/tx/group/${encodeURIComponent(gid)}`, [id, gid]);

  const display = useMemo(() => label ?? shorten(id ?? gid, 8, false), [id, label]);
  return <HFlex sx={sx}>{ id ? <ReceiptIcon color="primary" sx={{ mr: 1}} /> : <InsertLinkIcon color="primary" sx={{mr: 1}}/>}<Link href={href}>{display}</Link></HFlex>
}

export function TxnAddress({ address, sx, search=true }) {
  const navigate = useNavigate();
  const shorta = shorten(address, 8, false);
  const [display, setDisplay] = useState(shorta);
  const url = useMemo(() => `/address/${address}`, [address]);
  const click = useCallback(() => navigate(url), [url]);
  useEffect(() => {
    (async() => {
      const res = await lookupNFD(address);
      if (res[address])
        setDisplay(res[address]);
      else
        setDisplay(shorta);
    })()
  }, [address]);
  return <HFlex sx={sx}>{search?<SearchIcon className="fakeButton" onClick={click} color="primary"/>:null}<AccountCircleIcon className="fakeButton" onClick={click} color="primary" sx={{ mr: 1}} /><Link href={url}>{display}</Link></HFlex>
}

function HistoryRow({ rT, method, address, d: delta, id, assets, showAddress, children }) {
  return <RTableRow>
    { showAddress ? <RTableCell isx={{width: { xs: 1 }, justifyContent: 'space-between'}}>
      <TxnAddress address={address} />
      <TxnId id={id} sx={{ display: { xs: 'flex', md: 'none' } }} />
    </RTableCell> : null }
    <RTableCell isx={{ width: 1, justifyContent: 'flex-start' }} sx={{ display: { xs: showAddress ? 'none' : 'table-cell', md: 'table-cell' } }}>
      <TxnId id={id}  />
    </RTableCell>
    <RTableCell isx={{ width: { xs: 1 } }}>
      <strong>{getPrettyDrawMethod(method)}</strong>
    </RTableCell>
    <RTableCell isx={{ width: { xs: 1 } }}>
      {children}
    </RTableCell>
    <RTableCell isx={{ width: { xs: 1 } }}>
      <RoundTime t={rT} />
    </RTableCell>
  </RTableRow>
}

const ExecDraw = observer((props) => {
  const { d: delta } = props;
  return <HistoryRow {...props}>
    <MiniTeams teams={getDeltaNFTs(delta)} />
  </HistoryRow>
});

export const MiniTeam = observer(({assets, id, }) => {
  const { flag, name, rankLabel, rank, available } = assets.get(id) ?? {};
    const label = available ? rankLabel : 'Disqualified';
    return <Tooltip icon={<span className="bigflag">{flag}</span>}>
    <div style={{textAlign: 'center'}}><strong>{name}</strong><br/>{id}<br/>{label}<br/>Rank {rank}</div>
  </Tooltip>;
});

const SLOTS = ['slot1', 'slot2', 'slot3'];

function getDeltaNFTs(data) {
  return Object.entries(data).filter(([key, value]) => SLOTS.includes(key) && value).map(([_, v]) => v);
}

export const HistoryTitle = observer(({ history, globalAppState, children }) => {
  const [timeSince, setTimeSince] = useState(null);
  const [intt, setIntt] = useState();

  const refreshHistory = useCallback((manual) => {
    const { rewardsPoolAmount } = globalAppState;
    if (!rewardsPoolAmount) {
      return;
    }
    const spend = history.stats?.stats?.spend ?? 0;
    if (spend && rewardsPoolAmount && rewardsPoolAmount < spend * 1_000_000) {
      console.log('history was glitched!');
      history.reset();
    } else if (manual && history.timeSinceUpdate < 5_000) {
      console.log('updating history');
      history.reset();
    }
    history.getGlobalHistory();
  }, [globalAppState.rewardsPoolAmount]);

  useEffect(() => {
    if (history.timeSinceUpdate > 15_000) {
      refreshHistory();
    }
  }, [refreshHistory]);

  useInterval(() => history.getGlobalHistoryMaybe(), 60_000);

      return <HFlex sx={{ position: 'relative', height: 75, justifyContent: 'flex-end', width: 1 }}>
        <Typography align="left" sx={{position: 'absolute', left: "50%", top: '50%', width: 0.75, textAlign: 'center', transform: 'translate(-50%, -50%)', }} variant="h3">{children}</Typography>
    <div>
      { history.loading ? 'Loading' : 
      <IconButton onClick={() => refreshHistory(true)}>
        <ReplayIcon />
      </IconButton> }
    </div>
  </HFlex>
});

export const Leaderboard = observer( ({ history, assets }) => {
  const rawleaderboard = history.leaderboard;
  const hasAny = !!rawleaderboard.length;
  const loading = history.loading;
  const topTeams = assets.sort('rank').slice(0, 16).map(([id]) => id);
  const legendaryTeams = topTeams.slice(0, 2);
  const leaderboard = rawleaderboard.map(l => {
    l.numLeg = countLegendaries(l, legendaryTeams);
    return l;
  }).sort((a, b) => a.numLeg > b.numLeg ? -1 : (a.numLeg < b.numLeg ? 1 : (a.draws > b.draws ? -1 : 1)))
  .slice(0, 20);
  function countLegendaries(obj, leg) {
    return leg.reduce((sum, leg) => (obj.teams[leg] ?? 0) + sum, 0);
  }
  return <><VSeparator />
    <Opaque sx={{ width: 1 }}>
      <VFlex sx={{width: { xs: 0.95, md: 1, lg: 0.95, xl: 0.85,}}}>
        <HistoryTitle history={history} globalAppState={globalAppState} >
          Leaderboard<Tooltip sx={{mt: '-2px'}}>Rank is by number of Legendaries <u>drawn</u>,<br/>then number of draws as a tie-breaker.</Tooltip>
        </HistoryTitle>
        <Table>
          <TableBody>
            { leaderboard?.map(({address, draws, numLeg, teams}, i) =>
              <RTableRow key={i}>
                <RTableCell isx={{width: 1}}>
                  <HFlex>
                  Rank {i+1} {rankToLabel(i+1)}
                  </HFlex>
                  <HFlex sx={{display: { xs: 'flex', md: 'none' }, flexGrow: 1, justifyContent: 'flex-end' }}>
                    {draws} Draws
                  </HFlex>
                </RTableCell>
                <RTableCell>
                  <TxnAddress address={address}/>
                </RTableCell>
                <RTableCell sx={{display: { xs: 'none', md: 'table-cell'}}}>
                    {draws} Draws
                </RTableCell>
                <RTableCell isx={{justifyContent: 'center', width: 1}}>
                  { topTeams.filter(id => teams[id]).slice(0, 5).map(id => <Badge sx={{mr: 2}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} badgeContent={teams[id]} color="primary">
      <MiniTeam assets={assets} id={id} />
    </Badge>) }
                </RTableCell>
              </RTableRow>
            ) }
          </TableBody>
        </Table>
        { !hasAny ? <Typography sx={{fontSize: '5rem'}}>{loading ? '⌚' : '🤷'}</Typography> : null }
      </VFlex>
    </Opaque></>;
});

export const YourHistory = observer( ({ address, history, assets, label="Your" }) => {
  const yourHistory = address ? history.getYourHistory(address) : {};

  const loading = history.loading;
  const hasAny = !!yourHistory.history?.length;

    const teams = assets.sort("rank").map(([id]) => {
      const qt = yourHistory.stats?.teams && yourHistory.stats?.teams[id];
      if (qt)
        return [id, qt];
    }).filter(Boolean);

  return address ? <>
    <Box sx={{width: 1}}>
      <Stats {...yourHistory.stats} teams={teams} loading={history.loading} />
    </Box>
    <VSeparator />
    <Opaque sx={{ width: 1 }}>
      <VFlex sx={{width: { xs: 0.95, md: 0.9, lg: 0.75, xl: 0.7,}}}>
        <HistoryTitle history={history} globalAppState={globalAppState} >
          {label} History
        </HistoryTitle>
        <Table>
          <TableBody>
            { yourHistory.history?.map((data, i) => {
              return <HistoryEntry {...data} key={`yh-${i}`} />
            }) }
          </TableBody>
        </Table>
        { !hasAny ? <Typography sx={{fontSize: '5rem'}}>{loading ? '⌚' : '🤷'}</Typography> : null }
      </VFlex>
    </Opaque></> : <Opaque sx={{ width: 1 }} vh={65}><VFlex>
      <Typography align="center" sx={{ mb: 3 }} variant="h3">CONNECT WALLET</Typography>
      <Wallet account={account} fsm={fsm} />
    </VFlex></Opaque>
});

export const GlobalHistory = observer( ({ history, address, }) => {
  const [page, setPage] = useState(1);

  const allHistory = address ? history.getYourHistory(address) : history.getAllHistory();

  const perPage = 100;

  const hasMore = allHistory.history?.length > page * perPage

  const loadMore = useCallback((n) => {
    if (n > 1000) {
      const ret = window.confirm('This will load hundreds of thousands of elements.\n\nIn all likelihood, your browser will not have a good time.\n\nPress OK to proced anyway');
      if (!ret) {
        alert("Wise choice.");
        return;
      }
    }
    setPage(page + n);
  }, [page]);

  return <>
    <Box sx={{width: 1}}>
      <Stats {...allHistory.stats}  loading={history.loading} global={true} globalAppState={globalAppState} teams={null} />
    </Box>
    <VSeparator />
    <Opaque sx={{ width: 1 }}>
      <VFlex sx={{width: { xs: 1, lg: 0.9}}}>
        <HistoryTitle history={history} globalAppState={globalAppState} >
          CupStakes History
        </HistoryTitle>
        <VFlex sx={{ width: 1 }}>
          <Table>
            <TableBody>
              { allHistory.history?.slice(0, perPage * page).map((data, i) => {
                return <HistoryEntry showAddress={true} {...data} key={`yh-${i}`} />
              }) }
            </TableBody>
          </Table>
        </VFlex>
        { hasMore ? <VFlex>
          <HFlex>
            <Button variant="outlined" sx={{mt: 3}} onClick={() => loadMore(1)}>LOAD MORE</Button>
            <Button variant="outlined" sx={{ml: 1, mt: 3}} onClick={() => loadMore(9999)}>LOAD ALL</Button>
          </HFlex>
        </VFlex> : null }
      </VFlex>
    </Opaque>
  </>;
});

function rankToLabel(i) {
  if (i<=3)
    return '/ Legend';
  if (i <= 8)
    return '/ Epic';
  if (i <= 12)
    return '/ Rare';
}
