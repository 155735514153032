export const first = <>With <strong>Burn & Re-Draw</strong> you can discard a drawn CupStake for a discount.</>;

export default function() {
  return <div>
    <div>{first}</div>
    <div><br />Select up to 3 CupStakes to burn to see the discounted draw price.<br /></div>
    <div>Click the "BURN & RE-DRAW" button to execute the burn & re-draw transaction.</div>
    <br />
    <div>Note: You can not burn CupStakes after you have collected them into your wallet.</div>
  </div>;
}
