import { useState, useEffect } from 'react';
import { Logo, VFlex, } from '../components/Layout.jsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Team from '../components/Team.jsx';
import Preload from '../images/cupstakes-preload.js';

export default observer(function({ assets, two }) {
  const [sp] = useSearchParams();
  const spin = parseInt(sp.get("spin") ?? 9, 10);
  const rotate = parseFloat(sp.get("rotate") ?? 50.7);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--bg-spin-duration',
      `${spin}s`,
    );
    document.documentElement.style.setProperty('--rotate', `${rotate}deg`);
  }, [spin]);


  return <Logo />
});
