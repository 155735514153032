import { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { PeraWalletConnect } from "@perawallet/connect"
import MyAlgoConnect from '@randlabs/myalgo-connect';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import notifications from '../notifications.js';
import { optinApp, optoutApp } from '../algo.js';
import { HFlex, VFlex } from './Layout.jsx';
import { useNavigate, useLocation } from 'react-router';
import { uppercaseFirst, shorten } from '../utils.js';

const peraWallet = new PeraWalletConnect({ shouldShowSignTxnToast: false });

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Wallets({ account, fsm }) {
  const navigate = useNavigate();
  const location = useLocation();

  function handleConnectAlgoSigner(idx) {
    return async function() {
      hideModal();
      notifications.closeSnackbar('connect');
      try {
        const AlgoSigner = window.AlgoSigner;
        if (typeof AlgoSigner === 'undefined') {
          throw new Error('AlgoSigner is not installed');
        }
        await AlgoSigner.connect();
        const accts = await AlgoSigner.accounts({ ledger: 'MainNet' });
        if (accts.length && accts[idx].address) {
          account.setAddress(accts[idx].address);
          account.setWallet('algosigner');
          account.setWalletObject(AlgoSigner);
          redirectMaybe();
        } else {
          throw new Error('No accounts found');
        }
      } catch(e) {
        fsm.disconnect();
        notifications.error(e.message, undefined, 'connect');
      }
    }
  }

  async function handleConnectMyAlgo() {
    hideModal();
    notifications.closeSnackbar('connect');
    try {
      const myAlgoConnect = new MyAlgoConnect();
      const accounts = await myAlgoConnect.connect({ shouldSelectOneAccount: true });
      if (!accounts.length) {
        throw new Error('No accounts returned from MyAlgo');
      }
      account.setAddress(accounts[0].address);
      account.setWallet("myalgo");
      account.setWalletObject(myAlgoConnect);
      redirectMaybe();
    } catch(e) {
      fsm.disconnect();
      notifications.error(e.message, undefined, 'connect');
    }
  }

  function handleDisconnectWalletClick() {
    fsm?.disconnect();
  }

  async function handleConnectPera() {
    hideModal();
    notifications.closeSnackbar('connect');
    try {
      const newAccounts = await peraWallet.connect()
      peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
      account.setAddress(newAccounts[0]);
      account.setWallet("pera");
      account.setWalletObject(peraWallet);
    } catch(e) {
      if (e?.data?.type !== "CONNECT_MODAL_CLOSED") {
        notifications.error(e.message, undefined, 'connect');
      }
      fsm.disconnect();
    }
  }

  useEffect(() => {
    // Reconnect to the session when the component is mounted
    peraWallet.reconnectSession().then((accounts) => {
      // Setup the disconnect event listener
      peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);

      if (accounts.length) {
        account.setAddress(accounts[0]);
        account.setWallet("pera");
        account.setWalletObject(peraWallet);
        redirectMaybe();
      }
    }).catch();
  }, []);

  const disconnect = useCallback(() => {
    hideDisconnectModal();
    peraWallet.disconnect();
    fsm.disconnect();
  }, [account.address, account.wallet]);

  const [modalOpen, setModalOpen] = useState(false);
  const [disModalOpen, setDisModalOpen] = useState(false);

  const hideModal = () => setModalOpen(false);
  const showModal = () => setModalOpen(true);

  const showDisconnectModal = () => setDisModalOpen(true);
  const hideDisconnectModal = () => setDisModalOpen(false);

  const redirectMaybe = useCallback(() => {
    if (location.pathname === '/') {
      navigate('/play');
    }
  }, [location.pathname]);

  const display = useMemo(() => account.NFD ? account.NFD : `${account.address?.slice(0, 6)}..${account.address?.slice(-6)}`, [account.NFD, account.address]);

  const goOptout = useCallback(() => {
    hideDisconnectModal();
    navigate(`/optout`);
  }, [hideDisconnectModal]);

  const goHistory = useCallback(() => {
    hideDisconnectModal();
    navigate(`/data/#history`);
  }, [hideDisconnectModal]);

  return <div>
    { account.address ? <Button variant="outlined" onClick={showDisconnectModal}>{display}</Button> : 
          <Button variant="contained" onClick={showModal}>CONNECT</Button> }
    <Modal
      open={disModalOpen}
      onClose={hideDisconnectModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{position: 'absolute', top: 5, right: 5}}>
          <IconButton onClick={hideDisconnectModal}>
              <CloseIcon />
            </IconButton>
        </Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {account.NFD ? account.NFD : shorten(account.address || "", 8)}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          Wallet: { uppercaseFirst(account.wallet) }
          { account.availableBalance ? <><br/>Available balance: {account.availableBalance}</> : null }
          <br />Opted in to CupStakes: { account.optedIn ? 'Yes' : 'No' }
        </Typography>
        <HFlex>
          <HFlex sx={{flexGrow: 1, justifyContent: 'flex-start',}}><Button onClick={disconnect} variant="contained">DISCONNECT</Button></HFlex>
          <HFlex sx={{flexGrow: 1, justifyContent: 'flex-start',}}><Button onClick={goHistory} variant="outlined">HISTORY</Button></HFlex>
          { !account.optedIn ? <Button onClick={optinApp}>OPT IN</Button> : <Button variant="outlined" onClick={goOptout}>OPT OUT</Button> }
        </HFlex>
      </Box>
    </Modal>
    <Modal
      open={modalOpen}
      onClose={hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{position: 'absolute', top: 5, right: 5}}>
          <IconButton onClick={hideModal}>
              <CloseIcon />
            </IconButton>
        </Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Connect Wallet
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <VFlex>
            <Button
              className="wallet-button"
              variant="outlined"
              onClick={
                handleConnectPera
              }>
              {"Pera Wallet"}
            </Button>
            <Button
              className="wallet-button"
              variant="outlined"
              onClick={
                handleConnectMyAlgo
              }>
              {"MyAlgo Wallet"}
            </Button>
            <Button
              className="wallet-button"
              variant="outlined"
              onClick={
                handleConnectAlgoSigner(0)
              }>
              {"AlgoSigner Wallet"}
            </Button>
          </VFlex>
        </Typography>
      </Box>
    </Modal>
  </div>

}

export default observer(Wallets);
