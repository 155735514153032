import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

export default function({ value, copyIcon, ...props }) {
  let initIcon = copyIcon ? <ContentCopyIcon /> : null;
  const [label, setLabel] = useState('COPY');
  const [color, setColor] = useState('info');
  const [disabled, setDisabled] = useState(false);
  const [icon, setIcon] = useState(initIcon);

  const reset = () => { setColor('info'); setLabel('COPY'); setDisabled(false); setIcon(initIcon); }

  const doCopy = useCallback(() => {
    try {
      navigator.clipboard.writeText(value);
      setLabel('COPIED');
      setIcon(<CheckIcon />);
      setColor('success');
      setDisabled(true);
    } catch(e) {
      setLabel('ERROR');
      setColor('error');
    }
    setTimeout(reset, 2000);
  }, [value]);

  return <Button {...props} startIcon={icon} disabled={disabled} onClick={doCopy}>{label}</Button>
}
