import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import theme from './theme.js';
import { ThemeProvider } from '@mui/material/styles';
import Layout from './components/Layout.jsx';
import Fourohfour from './routes/Fourohfour';
import Home from './routes/Home';
import Play from './routes/Play';
import Data from './routes/Data';
import Audit from './routes/Audit';
import Redeem from './routes/Redeem.jsx';
import Gov from './routes/Gov';
import Optout from './routes/Optout';
import Trade from './routes/Trade';
import Draw from './routes/Draw.jsx';
import { account, fsm, doNotShowAgain, exa, globalAppState, localAppState, localAssets, assets, history } from './state/';
import FlagZoom from './routes/FlagZoom';
import Countdown from './routes/Countdown';
import Stopwatch from './routes/Stopwatch.js';
import FAQ from './routes/FAQ.js';
import RoyalT from './routes/RoyalT.js';
import HistoryBridge from './history-bridge.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import MediaTeam from './routes/MediaTeam.jsx';
import SpinBackPage from './routes/SpinBackPage.jsx';
import Address from './routes/Address.jsx';

function App() {
  return (
    <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/media/:code" element={<MediaTeam assets={assets} />} />
              <Route path="/media2/:code/:code2" element={<MediaTeam assets={assets} two={true} />} />
              <Route path="/spinnerme" element={<SpinBackPage />} />
              <Route path="/" element={<Layout localAppState={localAppState} />}>
                <Route path="/" exact={true} element={<Home assets={assets} globalAppState={globalAppState} />} />
                <Route path="play" element={<Play account={account} localAppState={localAppState} globalAppState={globalAppState} localAssets={localAssets} />} />
                <Route path="redeem" element={<Redeem account={account} fsm={fsm} assets={assets} localAssets={localAssets} />} />
                <Route path="data" element={<Data account={account} />} />
                <Route path="audit" element={<Audit />} />
                <Route path="gov" element={<Gov account={account} history={history} />} />
                <Route path="optout" element={<Optout account={account} assets={assets} localAssets={localAssets} fsm={fsm} />} />
                <Route path="trade" element={<Trade fsm={fsm} exa={exa} doNotShowAgain={doNotShowAgain} account={account} localAssets={localAssets} assets={assets} />} />
                <Route path="address/:address" element={<Address />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="homesend" element={<RoyalT history={history} account={account} localAssets={localAssets} assets={assets} />} />
                <Route path="*" element={<Fourohfour />} />
              </Route>
            </Routes>
          </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
