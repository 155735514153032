import './Team.css';
import Images from '../images/cupstakes-images.js';
import Box from '@mui/material/Box';

function CupStake(props) {
  const { code, sx, disqualified } = props;

  const imgSource = Images[code];

  return <Box className="minicupstake" sx={sx}>
          <img src={imgSource} />
    { disqualified ? <Box className="disqualified glow" sx={{color: disqualified}} >DISQUALIFIED</Box> : null }
    </Box>;
}

export default CupStake
