import { VSeparator, Opaque, HFlex, VFlex, Left, Link } from '../components/Layout.jsx';
import EmailIcon from '../images/email.png';
import GithubIcon from '../images/github.png';
import DiscordIcon from '../images/discord.png';
import TwitterIcon from '../images/twitter.png';

export default function({ mini, props }) {
  const className = mini ? 'social social-mini' : 'social';
  return <HFlex {...props}>
    <Link href="https://discord.gg/Hf92HpbSJU"><img className={className} src={DiscordIcon} /></Link>
    <Link href="https://twitter.com/CupStakes"><img className={className} src={TwitterIcon} /></Link>
    {!mini && <><Link href="https://github.com/CupStakes/"><img className="social dark" src={GithubIcon} /></Link>
      <Link href="mailto:hello@cupstakes.world"><img className="social" src={EmailIcon} /></Link></> }
  </HFlex>
}
