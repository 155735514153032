import ARG from './ARG-hi.png';
import AUS from './AUS-hi.png';
import BEL from './BEL-hi.png';
import BRA from './BRA-hi.png';
import CAN from './CAN-hi.png';
import SUI from './SUI-hi.png';
import CMR from './CMR-hi.png';
import CRC from './CRC-hi.png';
import GER from './GER-hi.png';
import DEN from './DEN-hi.png';
import ECU from './ECU-hi.png';
import ENG from './ENG-hi.png';
import ESP from './ESP-hi.png';
import FRA from './FRA-hi.png';
import GHA from './GHA-hi.png';
import CRO from './CRO-hi.png';
import IRN from './IRN-hi.png';
import JPN from './JPN-hi.png';
import KOR from './KOR-hi.png';
import MAR from './MAR-hi.png';
import MEX from './MEX-hi.png';
import NED from './NED-hi.png';
import POL from './POL-hi.png';
import POR from './POR-hi.png';
import QAT from './QAT-hi.png';
import KSA from './KSA-hi.png';
import SEN from './SEN-hi.png';
import SRB from './SRB-hi.png';
import TUN from './TUN-hi.png';
import URU from './URU-hi.png';
import USA from './USA-hi.png';
import WAL from './WAL-hi.png';
import back from './backface-hi.png';

export default { ARG, AUS, BEL, BRA, CAN, SUI, CMR, CRC, GER, DEN, ECU, ENG, ESP, FRA, GHA, CRO, IRN, JPN, KOR, MAR, MEX, NED, POL, POR, QAT, KSA, SEN, SRB, TUN, URU, USA, WAL, back, };
