import { subscribe } from '../event-stream.js';

export default class Exa {
  items=[]
  hash=''

  constructor() {
    subscribe('/stream', this.newData);
  }

  setItems(data) {
    this.items = data;
  }

  setHash(data) {
    this.hash = data;
  }

  newData = (data, ...props) => {
    try {
      const parsed = JSON.parse(data);
      this.setItems(parsed.items);
      this.setHash(parsed.hash);
    } catch(e) {
      console.error('Error parsing EXA data', e.message);
    }
  }

  getASAEntries(id) {
    const sid=String(id);
    const relevantEntries = this.items.filter(({forSale}) => Object.keys(forSale).includes(sid));
    let fromPrice = Math.min(...relevantEntries.map(({forPrice: {unit, amount}}) => unit === "ALGO" || unit === "gALGO" ? amount : Infinity));
    if (fromPrice === Infinity)
      fromPrice = 0;
    const totalQuantity = relevantEntries.map(({forSale}) => forSale[sid]).reduce((sum, c) => sum+c, 0);
    const baskets = relevantEntries.length;
    return { id, relevantEntries, baskets, totalQuantity, fromPrice, url: `https://exa.market/asset/${id}` };
  }
}
