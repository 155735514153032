import { useMemo, useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, VSeparator, Opaque, HFlex, VFlex, BigLoader } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { sendCollectTxns, sendDraw1Txns } from '../algo.js';
import { account, history, assets, localAssets, localAppState, globalAppState } from '../state/';
import Play from '../components/Play.jsx';
import NFTs from '../components/NFTs.jsx';
import AllNFTTable from '../components/AllNFTTable.jsx';
import AllNFTs from '../components/AllNFTs.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { YourHistory, GlobalHistory } from '../components/History.jsx';
import { shorten } from '../utils.js';
import CopyButton from '../components/CopyButton.jsx';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { lookupNFD } from '../utils.js';

function Address() {
  const { address } = useParams();
  const [NFD, setNFD] = useState();
  const navigate = useNavigate();

  const goBack = useCallback(() => navigate(-1), []);
  const goExplore = useCallback(() => window.open(`https://algoscan.app/address/${address}`), [address]);
  
  const shortAddr = useMemo(() => shorten(address, 8, false), [address]);

  useEffect(() => window.scroll(0, 0), []);

  useEffect(() => {
    (async () => {
      setNFD(null);
      const res = await lookupNFD(address);
      if (res)
        setNFD(res[address]);
    })()
  }, [address]);

  return <>
    <VSeparator id="data" />

    <Opaque>
      <Typography align="center" id="#table" variant="h2">PLAYER HISTORY</Typography>
      <VFlex>
        <Typography color="primary" sx={{ fontFamily: 'Maldini', fontSize: '1.4rem', display: { xs: 'none', md: 'block' }} }>{NFD ? <strong>{NFD}</strong> : address}</Typography>
        <Typography sx={{display: { xs: 'block', md: 'none' }, fontFamily: 'Maldini', fontSize: '1.4rem', }} color="primary">{NFD ?? shorten(address)}</Typography>
        <HFlex sx={{ mt: 1 }}>
          <Button variant="outlined" startIcon={<ArrowBackIosNewIcon />} onClick={goBack} size="small">BACK</Button>
          <CopyButton sx={{ ml: 2 }} copyIcon={true} variant="outlined" size="small" value={address} />
          <Button sx={{ ml: 2 }} variant="outlined" onClick={goExplore} startIcon={<SearchIcon />} size="small">EXPLORE</Button>
        </HFlex>
      </VFlex>
    </Opaque>

    <VSeparator />

    <VFlex>
      <YourHistory assets={assets} label={NFD ?? shortAddr} address={address} history={history} />
    </VFlex>

    <VSeparator />
  </>
}

export default observer(Address);

