import { useState, useEffect, useCallback, useMemo, } from 'react';
import Typography from '@mui/material/Typography';
import { Link, VFlex, HFlex, Left, Opaque, VSeparator } from '../components/Layout.jsx';
import { afterRoyalT, ALGO } from '../constants.js';
import useInterval from '../hooks/use-interval.js';
import { oxxayReceived } from '../algo/indexer.js';
import { printALGO } from '../utils.js';
import { sendHomesend } from '../algo.js';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Tooltip from '../components/Tooltip.jsx';
import Wallet from '../components/Wallets.jsx';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { account, fsm, assets, localAssets } from '../state/index.js';
import Airplane from '@mui/icons-material/AirplanemodeActive';
import { TxnAddress } from '../components/History.jsx';
import { TableRow as RTableRow, TableCell as RTableCell } from '../components/responsive-table.js'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const HS_AMT = 3;

function rankOddsAll(hs, pp) {
  if (!Object.keys(hs).length || !Object.keys(pp).length)
    return { items: [], totalOdds: 'Loading' };
  const players = {};
  let totalOdds = 0;
  for(const [address, homesends] of Object.entries(hs)) {
    if (address.startsWith('RYUZAKI')||address.startsWith('DTHIRTEEN'))
      continue;
    const score = Object.values(homesends).reduce((sum, cur) => sum + cur * 30, 0);
    if (score<1)
      continue;
    players[address] = players[address] ?? { pp: 0, hs: 0 };
    players[address].hs += score;
    players[address].t = players[address].hs;
    totalOdds += score;
  }
  for(const [address, p] of Object.entries(pp)) {
    if (p<1)
      continue;
    if (address.startsWith('RYUZAKI')||address.startsWith('DTHIRTEEN'))
      continue;
    players[address] = players[address] ?? { pp: 0, hs: 0 };
    players[address].pp += p / 1_000_000;
    players[address].t = players[address].pp + players[address].hs;
    totalOdds += p / 1_000_000;
  }
  for(const [address, {t}] of Object.entries(players)) {
    players[address].odds = t/totalOdds;
  }
  const sorted = Object.entries(players).sort((a, b) => a[1].t < b[1].t ? 1 : -1);
  return { items: sorted, total: totalOdds };
}

function rankOdds(address, hs, pp) {
  if (!address || !Object.keys(hs).length || !Object.keys(pp).length)
    return;
  const players = {};
  let totalOdds = 0;
  for(const [address, homesends] of Object.entries(hs)) {
    players[address] = players[address] ?? 0;
    const score = Object.values(homesends).reduce((sum, cur) => sum + cur * 30, 0);
    players[address] += score;
    totalOdds += score;
  }
  for(const [address, p] of Object.entries(pp)) {
    players[address] = players[address] ?? 0;
    players[address] += p / 1_000_000;
    totalOdds += p / 1_000_000;
  }
  const sorted = Object.entries(players).sort((a, b) => a[1] < b[1] ? 1 : -1);
  const yourIdx = sorted.findIndex(([a]) => a === address);
  let rank;
  let odds;
  const totalPlayers = Object.keys(players).length;
  if (yourIdx === -1) {
    rank = 'N/A';
    players[address] = 0;
    odds = '0%';
  } else {
    odds = (players[address] / totalOdds * 100).toFixed(2) + '%';
    rank = `${(yourIdx + 1)}/${totalPlayers}`;
  }
  players[address] += 30;
  const sortedN = Object.entries(players).sort((a, b) => a[1] < b[1] ? 1 : -1);
  const yourIdxN = sortedN.findIndex(([a]) => a === address);
  const nextRank = `${(yourIdxN + 1)}/${Object.keys(players).length}`;
  const nextOdds = (players[address] * 100 / (30 + totalOdds)).toFixed(2) + '%';
  return {
    rank,
    odds,
    nextRank,
    nextOdds,
  };
}

const MiniTeam = ({assets, id, flag, name, amount, noTip, remaining }) => {
  if (!id)
    return;
  if (!flag) {
    const a = assets.get(id);
    flag = a.flag;
    name = a.name;
  }
  let extra = remaining == amount ? <><br/>CLICK to HOMESEND</> : <></>;
  if (remaining < HS_AMT && remaining == amount) {
    extra = <><br/>You need {HS_AMT - amount} more</>;
  }
  return <Tooltip icon={<span className="bigflag">{flag}</span>}>
    <div style={{textAlign: 'center'}}><strong>{name}</strong>{ noTip ? null : <><br/>You have {amount == remaining ? amount+'x' : `${remaining}x remaining`}{extra}</>}</div>
  </Tooltip>;
};


const MIN = 60 * 1000;
const HOUR = MIN * 60;
const DAY = HOUR * 24;

function DateDiff({ to, end='Challenge Expired', startLabel='Ends in: ' }) {
  const [label, setLabel] = useState('');
  const [ended, setEnded] = useState(false);

  function calcLabel(value) {
    const now = Date.now();
    const then = to;
    const diff = then - now;
    if (diff > 0) {
      const dayDiff = Math.floor(diff / DAY);
      let hours = dayDiff ? diff - dayDiff * DAY : diff;
      const hourDiff = Math.floor(hours / HOUR);
      let minutes = hourDiff ? hours - hourDiff * HOUR : hours;
      const minuteDiff = Math.floor(minutes / MIN);
      const dateLabel = dayDiff > 0 ? `${dayDiff} Day${dayDiff != 1 ? 's' : ''} ` : '';
      setLabel(`${dateLabel}${hourDiff} Hrs ${minuteDiff} Min`);
    } else if (!ended) {
      setEnded(true);
    }
  }
  useEffect(() => calcLabel(to), [to]);
  useInterval(() => calcLabel(to), 60_000);
  return ended ? <Typography className="glow2" variant="h2">{end}</Typography>
    : <Typography className="glow" variant="h3">{startLabel}{label}</Typography>
}


function HomesendOver() {
  return <Opaque>
    <Left>
      <Typography sx={{alignSelf: 'center'}} id="homesend" variant="h3">HOME... SENT</Typography>
      <Typography><strong>The Homesend challenge window is now over!</strong></Typography>
      <Typography>The draw will be executed between Dec. 10 and 11.</Typography>
      <Typography><Link href="/faq#what-is-royal">Questions?</Link></Typography>
    </Left>
  </Opaque>
}
const Homesend = observer(function({ account, fsm, assets, localAssets, refreshHomesend, }) {
  const navigate = useNavigate();
  const [homesend, setHomesend] = useState({});

  const addHomesend = (aid) => {
    const oldQ = homesend[aid] ?? 0;
    const diff = localAssets.get(aid).amount - oldQ;
    if (diff < HS_AMT) {
      const missing = HS_AMT - diff;
      const { name } = assets.get(aid);
      if (window.confirm(`You need ${missing} more ${name} CupStakes to HOMESEND them.\n\nClick OK to go to the Trade page and see if there are any for sale`)) {
        navigate('/trade#buy:homesend');
      }
      return;
    }
    const newhs = { ...homesend, [aid]: oldQ + HS_AMT }
    if (Object.keys(newhs).length > 16) {
      alert('You can only HOMESEND 16 types of CupStakes at once');
      return;
    }
    setHomesend(newhs);
  };

  const l = localAssets.entries().map(([id, { amount }]) => {
      if (id === "free" || amount === 0)
        return;
      const a = assets.get(Number(id));
      if (a.available) {
        return;
      }
      return { ...a, amount }
    }).filter(Boolean).sort((a, b) => a.amount < b.amount ? 1 : -1);

  const canHomeSend = Object.entries(homesend).length;

  const performHomesend = async () => {
    await sendHomesend(homesend)
    setHomesend({});
    setTimeout(() => refreshHomesend(), 5_000);
  }

  return <><Opaque>
      <Left>
        <Typography sx={{alignSelf: 'center'}} id="homesend" variant="h3">HOMESEND</Typography>
        <Typography><strong>Collect 3x of the same Disqualified CupStake and send them home!</strong></Typography>
        <Typography>Each HOMESEND earns you 30 Royal-T Points.</Typography>
        <Typography>Unclear? Read the <Link href="/faq#what-is-royal">frequently asked questions.</Link></Typography>
      </Left>
  </Opaque>
  <VSeparator vh={1} />
  <Opaque>
      { account.address ? <>
      <Typography variant="h3" sx={{ mt: 2 }}>1/ Select CupStakes</Typography>
      <Typography>Click on a disqualified CupStake you have 3x of to add it to the HOMESEND plane.</Typography>
      <HFlex>{ l.map(({ id, code, name, flag, amount }, i)=> {
        const remaining = homesend[id] ? amount - homesend[id] : amount;
        if (remaining < 1)
          return null;
        return <Button key={i} onClick={() => addHomesend(id)}>
        <Badge sx={{mr: 2}} className={remaining < HS_AMT ? 'mono' : ''} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} badgeContent={remaining} color="primary">
              <MiniTeam flag={flag} name={name} id={id} amount={amount} remaining={remaining} />
            </Badge>
        <MiniTeam code={code} />
      </Button>}).filter(Boolean)}
      </HFlex>
        <Typography sx={{mb: 2}}>Need more? We have a <Link href="/trade#buy:homesend">HOMESEND Trade view</Link> for cheap triplet opportunities.</Typography>
      <Typography variant="h3" sx={{ mt: 2 }}>2/ HOMESEND them</Typography>
      <HFlex>
      {Object.entries(homesend).map(([id, amount], i) => 
        <Badge key={i} sx={{mr: 2}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} badgeContent={amount} color="primary">
          <MiniTeam noTip={true} assets={assets} id={id} />
        </Badge>
      )}
      </HFlex>
      {!canHomeSend ?
        <>Select CupStakes</> :
        <>Royal-T Points: {Object.values(homesend).reduce((sum, cur) => sum + cur*10, 0)}</>
      }
        <Button startIcon={<Airplane />} onClick={performHomesend} disabled={!canHomeSend} variant="contained">HOMESEND</Button>
      </> : <Opaque sx={{ width: 1 }}><VFlex>
      <Typography align="center" sx={{ mb: 3 }} variant="h3">CONNECT WALLET</Typography>
      <Wallet account={account} fsm={fsm} />
    </VFlex></Opaque>
    }
  </Opaque></>
});

export default observer(function ({ history, account }) {
  const [playPrize, setPlayPrize] = useState(0);
  const [royaltyPrize, setRoyaltyPrize] = useState(0);
  const [totalPrize, setTotalPrize] = useState(0);
  const [render, setRender] = useState(0);
  const [playPoints, setPlayPoints] = useState({});
  const [globalHomesends, setGlobalHomesends] = useState({});
  const [homesendPoints, setHomesendPoints] = useState({});
  const [rank, setRank] = useState({});

  useEffect(() => {
    (async() => {
      const playPoints = await history.getRoyalTPlayPoints();
      setPlayPoints(playPoints);
      const playRewards = Object.values(playPoints).reduce((sum, cur) => sum+cur, 0) * 0.05 / 1_000_000;
      setPlayPrize(playRewards);
    })()
  }, [history.lastRound]);

  const updateOxxay = useCallback(async() => {
    const { royalties, homesends } = await oxxayReceived();
    setRoyaltyPrize(royalties / 1_000_000);
    setGlobalHomesends(homesends);
  }, []);

  useEffect(() => { updateOxxay(); }, []);
  useInterval(() => { updateOxxay() }, 60_000); // TODO

  useEffect(() => {
    setHomesendPoints(globalHomesends[account.address] ?? {});
  }, [globalHomesends, account.address]);

  useEffect(() => {
    setRank(rankOdds(account.address, globalHomesends, playPoints));
  }, [globalHomesends, playPoints, account.address]);

  const hsScore = Object.values(homesendPoints).reduce((sum, cur) => sum + cur * 30, 0);
  const ppScore = (playPoints[account.address] ?? 0) / 1_000_000;
  const globalPPScore = (Object.values(playPoints).reduce((sum, cur) => sum + cur / 1_000_000, 0) ?? 0);
  const globalHSScore = Object.values(globalHomesends).reduce((sum, cur) => sum + 
    Object.values(cur).reduce((sum, cur) => sum + cur * 30, 0)
  , 0);

  useEffect(() => {
    if (!playPrize || !royaltyPrize)
      setTotalPrize('Loading');
    else
      setTotalPrize(playPrize + royaltyPrize)
  }, [playPrize, royaltyPrize]);

  const xsw = 0.8;

  return <div>
    <VSeparator />
    <Opaque h2>
      <Typography color="faded" variant="h2">HOMESEND Royal-T Challenge</Typography>
    </Opaque>

    <VSeparator vh={3} />
    <VFlex>
      <DateDiff pre="Ends in: " to={afterRoyalT} />
    </VFlex>
    <VSeparator vh={3} />

    <Opaque sx={{justifyContent: 'space-around', flexDirection: { xs: 'column', md: 'row'}}}>
      <VFlex sx={{ width: { xs: xsw, md: 'auto' }}}>
        <Typography variant="h3">WHAT IT IS</Typography>
        <Typography className="al">1 winner will claim the Royal-T prize:</Typography>
        <Typography className="al">100% of Royalties & 50% of Revenue</Typography>
        <Typography className="al">During rest period / Dec. 6 - Dec. 9.</Typography>
        <Typography className="al"><strong>Draw, Burn or HOMESEND to compete!</strong></Typography>
      </VFlex>
      <VFlex sx={{ width: { xs: xsw, md: 'auto' }}}>
        <Typography sx={{ mt: { xs: 3, md: 0 }}} variant="h3">ROYAL-T PRIZE</Typography>
        <Typography className="al">Rewards pool share (5%): {printALGO(playPrize)}</Typography>
        <Typography className="al">Royalties share (100%): {printALGO(royaltyPrize)}</Typography>
        <Typography className="al">Increases until Dec. 9 1500 GMT</Typography>
        <Typography className="al"><strong>Total Prize so far: {printALGO(totalPrize)}</strong></Typography>
      </VFlex>
      <VFlex sx={{ width: { xs: xsw, md: 'auto' }}}>
        <Typography sx={{ mt: { xs: 3, md: 0 }}} variant="h3">HOW TO WIN</Typography>
        <Typography className="al">Draw/Burn CupStakes or <Link href="#homesend">HOMESEND</Link>:</Typography>
        <Typography className="al">Every {ALGO} spent = 1 Royal-T Point</Typography>
        <Typography className="al">Every HOMESEND = 30 Royal-T Points</Typography>
        <Typography className="al"><strong>Your Royal-T Points = Your odds to win</strong></Typography>
      </VFlex>
    </Opaque>

    <VSeparator vh={1} />

    <Opaque sx={{justifyContent: 'space-around', flexDirection: { xs: 'column', md: 'row'}}}>
      <VFlex sx={{ width: { xs: xsw, md: 'auto' }}}>
        <Typography variant="h3">YOUR ROYAL-T Pts</Typography>
        <Typography className="al"><strong>Total Royal-T points: {(ppScore + hsScore).toFixed(2)}</strong></Typography>
        <Typography className="al">HOMESEND points: {((hsScore ?? 0)).toFixed(2)}</Typography>
        <Typography className="al">ALGO points: {(ppScore).toFixed(2)}</Typography>
      </VFlex>
      <VFlex sx={{ width: { xs: xsw, md: 'auto' }}}>
        <Typography sx={{ mt: { xs: 3, md: 0 }}} variant="h3">YOUR ODDS</Typography>
        <Typography className="al"><strong>Your odds: {rank?.odds ?? 'N/A'}</strong></Typography>
        <Typography className="al">Your rank: {rank?.rank ?? 'N/A'}</Typography>
        <Typography className="al">+1 HOMESEND odds: {rank?.nextOdds ?? 'N/A'}</Typography>
      </VFlex>
      <VFlex sx={{ width: { xs: xsw, md: 'auto' }}}>
        <Typography sx={{ mt: { xs: 3, md: 0 }}} variant="h3">TOTAL ROYAL-T Pts</Typography>
        <Typography className="al"><strong>All Royal-T points: {(globalHSScore + globalPPScore).toFixed(2)}</strong></Typography>
        <Typography className="al">All HOMESEND points: {globalHSScore}</Typography>
        <Typography className="al">All ALGO points: {globalPPScore.toFixed(2)}</Typography>
      </VFlex>
    </Opaque>

    <VSeparator vh={7} />

    { Date.now() < afterRoyalT ? <Homesend refreshHomesend={updateOxxay} account={account} fsm={fsm} assets={assets} localAssets={localAssets} /> : <HomesendOver /> }

    <VSeparator vh={7} />

    <HomesendLeaderboard account={account} homesends={globalHomesends} playPoints={playPoints} />

    <VSeparator />

  </div>;
});

const HomesendLeaderboard = observer(({ homesends, playPoints, account }) => {
  const ranked = rankOddsAll(homesends, playPoints);
  // const s = ranked?.items?.map(([a, {odds}]) => `${a.slice(0, 10)},${odds}`).join('\n');
  return <Opaque>
          <VFlex sx={{width: { xs: 0.95, md: 1, lg: 0.95, xl: 0.85,}}}>
            <Typography sx={{alignSelf: 'center'}} id="homesend" sx={{mb: 1, mt: 1}} variant="h3">ROYAL-T STANDINGS</Typography>
            <Typography>Total Points: {ranked.total?.toFixed(2)}</Typography>
        <Table>
          { ranked?.items?.length ? 
            <>
              <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Win Odds</TableCell>
                  <TableCell>Royal-T Points</TableCell>
                  <TableCell>Point Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { ranked?.items?.map(([address, { t, odds, pp, hs }], i) =>
                <RTableRow key={i} sx={account?.address === address ? { border: '1px var(--primary) solid', background: 'var(--bg-radial-color-3)' } : null }>
                  <RTableCell isx={{width: 1}}>
                    <HFlex>
                      Rank {i+1}
                    </HFlex>
                  </RTableCell>
                  <RTableCell>
                    <TxnAddress address={address}/>
                  </RTableCell>
                  <RTableCell>
                    <Box sx={{ display: { xs: 'inline-block', md: 'none'}}}>
                      Odds:&nbsp;
                    </Box> {(odds*100).toFixed(1)}%
                  </RTableCell>
                  <RTableCell isx={{justifyContent: 'center', width: 1}}>
                    {t} Royal-T Pts
                  </RTableCell>
                  <RTableCell>
                    {Math.round(hs/30)} HOMESENDS + {ALGO}{pp}
                  </RTableCell>
                </RTableRow>
              ) }
              </TableBody></> : null }
        </Table>
        { !ranked?.items.length ? <Typography sx={{fontSize: '5rem'}}>⌚</Typography> : null }
      </VFlex>
  </Opaque>;
});
/*
 *
    <Opaque>
    </Opaque>

      <Typography>The more Royal-T points you have, the better your odds at winning the prize.</Typography>
 */
