import { useMemo, useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { HFlex, VFlex } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { sendCollectTxns, sendDraw1Txns } from '../algo.js';
import { account, assets, localAssets, localAppState, globalAppState } from '../state/';
import Play from '../components/Play.jsx';
import NFTs from '../components/NFTs.jsx';
import AllNFTs from '../components/AllNFTs.jsx';
import { refreshAppLocalState } from '../algo.js';
import Team from '../components/Team.jsx';
import Zoom from '../components/Zoom.jsx';
import teams from '../teams.json';

const text = 'CUPSTAKES'.split('');
const cupstakes = teams.map((team, i) => <Team key={`spin-${i}`} code={team.countryCode} />);
// const items = interleave(someFlags, cupstakes);
const items = text.concat(cupstakes);

function Draw({account, localAppState}) {
  const navigate = useNavigate();

  console.log('render');
  const [iterRound, setIterRound] = useState();
  const [before, setBefore] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    console.log('effect', !!account);
    (async () => {
      console.log(localAppState.drawAmount, 'before',);
      const { round } = await refreshAppLocalState();
      console.log('round', round, 'lDA', localAppState.drawAmount);
      // TODO check not logged in? redirect
      setTimeout(() => {
        if (!localAppState.drawAmount) {
          console.log('no draw amount, navigating back');
          window.location.href='/redeem';
        }
        const { slot1, slot2, slot3 } = localAppState;
        setBefore([slot1, slot2, slot3]);
        setIterRound(localAppState.drawRound + 2);

      }, 2000);
    })()
  }, [account.address]);

  useEffect(() => {
    console.log("effect 2");
    if (!iterRound)
      return;
    (async () => {
      console.log("waiting for", iterRound, "want", localAppState.drawRound);
      await refreshAppLocalState(undefined, iterRound);
      if (localAppState.drawAmount > 0) {
        setIterRound(iterRound + 1);
      } else {
        const { slot1, slot2, slot3 } = localAppState;
        const changed = [slot1, slot2, slot3].filter((s, i) => {
          return s !== before[i];
        })
        if (changed.length) {
          console.log('got result');
          setFinal(<HFlex>{changed.map((slot, i) => 
            <Team key={`draws-${i}`}{...assets.get(slot)} /> 
          )}</HFlex>);
        }
      }
    })()
  }, [iterRound]);

  const [final, setFinal] = useState("?");

  return <VFlex sx={{ flexGrow: 1 }}>
    <Zoom items={items} final={final} />
  </VFlex>
}

function shuffle(arr) {
  return [...arr].sort(() => Math.random() - 0.5);
}

function interleave(...arrs) {
  const out = [];
  const ls = arrs.map(arr => arr.length);
  const maxL = Math.max(...ls);
  for(let i=0; i<maxL; i++) {
    for(const arr of arrs) {
      if (i < arr.length) {
        out.push(arr[i]);
      }
    }
  }
  return out;
}

export default observer(Draw);
