import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { roundForRoyalT, globalHistory, filterAccount, quickStats, txnStats } from '../algo/indexer.js';
import { load, save } from './persist.js';
import { convertObjectSnakeCaseToCamelCase } from '../utils.js';
import constants from '../constants.json';
import get from 'lodash/get';
import { afterRoyalT } from '../constants.js';

const { genesisRound } = convertObjectSnakeCaseToCamelCase(constants);

const _type = 'indexer';

const DEBUG = false;

class Indexer {
  lastRound = null;
  lastUpdated = null;
  loading = false;
  data = [];
  addressStates = {};

  get timeSinceUpdate() {
    return Date.now() - this.lastUpdated;
  }

  constructor(account) {
    this.account = account;
    makeAutoObservable(this);

    load(_type, (val) => { 
      setTimeout(async () => {
        if (val && (val.length || val.data)) {
          const data = val.data ?? val ?? [];
          if (!val.v || val.v < 3) {
            console.log('clearing old indexer');
            this.setAddressStates({});
            this.setData([]);
          } else {
            DEBUG && console.log("indexer from memory", data.length);
            this.setAddressStates(val.as);
            this.setData(data);
          }
        }
      }, 1);
    }, true);

    reaction(() => this.data, (newValue, prevValue) => {
      if (!save(_type, { data: this.data, as: this.addressStates, v: 3 })) {
        if (DEBUG) {
          const res = save(_type, { data: this.data.slice(0, 500), as: this.addressStates, v: 3 });
          console.log('retry fixed it');
        }
      }
    });
  }

  setLastRound(round) {
    this.lastRound = round;
  }

  setLoading(value) {
    this.loading = value;
  }

  setData(data) {
    this.data = data;
    this.ids = new Set(data.map(({id}) => id));
    if (data.length && data[0]) {
      const lastConfirmed = data.slice(0, 10).find(({r}) => r).r;
      if (lastConfirmed) {
        if (this.lastRound !== lastConfirmed)
          this.setLastRound(lastConfirmed)
        DEBUG && console.log("setting last round", lastConfirmed);
      }
    }
  }

  setLastUpdated(value) {
    this.lastUpdated = value;
  }

  setAddressStates(as) {
    this.addressStates = as;
  }

  async getGlobalHistoryMaybe(round) {
    if (this.timeSinceUpdate > 15_000) {
      this.getGlobalHistory();
    }
  }

  async getGlobalHistory(round) {
    if (this.loading === true) {
      console.warn('Already loading history');
      return;
    }
    DEBUG && console.log("loading history");
    round = round ?? (this.lastRound ? this.lastRound + 1 : genesisRound);
    this.setLoading(true);
    const [newData, addressStates] = await globalHistory(round, this.addressStates);
    const history = [...newData, ...this.data];
    runInAction(() => {
      try {
        this.setLastUpdated(Date.now());
        if (this.data.length !== history.length) {
          DEBUG && console.warn("UPDATE history");
          this.setAddressStates(addressStates);
          this.setData(history);
        } else {
          DEBUG && console.warn("No history update");
        }
      } catch(e) {
        console.error(e);
      }
      this.setLoading(false);
    });
  }

  getYourHistory(_address) {
    const address = _address ?? this.account.address;
    if (!address || !this.data?.length)  {
      return [];
    }
    const history = filterAccount(address, this.data);
    const stats = txnStats(history);
    return { history, stats };
  }

  get leaderboard() {
    const perAccount = this.data.reduce((stats, txn) => {
      const { address } = txn;
      if (!txn.address)
        return stats;
      if (!stats[address])
        stats[address] = []
      stats[address].push(txn);
      return stats;
    }, {});
    const stats = [];
    for(const [address, txns] of Object.entries(perAccount)) {
      const qs = quickStats(txns);
      stats.push(qs);
    }
    stats.sort((a, b) => a.draws > b.draws ? -1 : 1).splice(50);
    return stats;
  }

  get stats() {
    return txnStats(this.data ?? []);
  }

  getAllHistory() {
    const stats = this.stats;
    return { history: this.data?.filter(({method}) => method !== 'collect'), stats };
  }

  reset() {
    this.setLastRound(genesisRound);
    this.setLastUpdated(null);
    this.setAddressStates({});
    this.setData([]);
  }

  getRoyalTPlayPoints() {
    const points = {};
    for(const d of this.data) {
      if (d.rT >= afterRoyalT/1000) {
        continue;
      }
      if (d.r <= roundForRoyalT) {
        break;
      }
      const amt = get(d, 'd.draw_amount_paid');
      if (amt > 0) {
        const { address } = d;
        points[address] = points[address] ?? 0;
        points[address] += amt;
      }
    }
    return points;
  }

}

export default Indexer;
