import './Zoom.css';
import './Zoom-delays.css';

export default function({ items, final }) {
  const elements = [];
  elements.push(...items.map((child, i) => <div key={`zoom-${i}`} className="zoom-container"><span className={`zoom whitebg maldinizoom delay-${i}`}>{child}</span></div>));
  if (final) {
    const i = elements.length;
    elements.push(<div key={`zoom-final`} className="zoom-container"><span className={`zoomfinal maldini delay-${i}`}>{final}</span></div>);
  }
  elements.reverse();
  return elements;
}
