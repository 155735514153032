import { useMemo, useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Opaque, VSeparator, HFlex, VFlex } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { sendCollectTxns, sendDraw1Txns } from '../algo.js';
import { account, assets, localAssets, localAppState, globalAppState, doNotShowAgain } from '../state/';
import PlayComponent from '../components/Play.jsx';
import NFTs from '../components/NFTs.jsx';

const OptedIn = observer(function LoggedIn({account, children}) {
  return account.optedIn ? <>{children}</> : null;
});

const LoggedIn = observer(function LoggedIn({account, children}) {
  return account.address ? <>{children}</> : null;
});

function Visible({children, when}) {
  return when ? <>{children}</> : null;
}

function Play({account, globalAppState, localAssets}) {
  const ownTeams = account.address ? localAssets.entries().filter(([id, ass]) => id !== "free" && ass.amount).length : 0;
  
  const minutes = globalAppState.pausingSoon ? Math.ceil((globalAppState.pausingAt-(Date.now() / 1000))/60) : 0;

  const header = globalAppState.isPaused ? "Fin" : (
    globalAppState.pausingSoon ? "Pausing Soon "+minutes+"'" : "Play CupStakes"
  );

  return <>
    <VSeparator />

    <Opaque h1 op={0}>
      <Typography sx={{mb: 2}} className="glow" align="center" variant="h1">{header}</Typography>
    </Opaque>

    <VSeparator />

    <PlayComponent ownTeams={ownTeams} doNotShowAgain={doNotShowAgain} account={account} assets={assets} localAssets={localAssets} localAppState={localAppState} globalAppState={globalAppState} />

    <Visible when={ownTeams}>
      <VSeparator />
      <Opaque>
        <Typography align="center" variant="h3">YOUR WALLET TEAMS [{ownTeams}/32]</Typography>
        <NFTs assets={assets} localAssets={localAssets} />
      </Opaque>
    </Visible>

    <VSeparator />

  </>
}

export default observer(Play);
