const SECOND = 1000,
  MINUTE = 60 * SECOND,
  HOUR = 60 * MINUTE,
  DAY = 24 * HOUR,
  WEEK = 7 * DAY,
  YEAR = DAY * 365,
  MONTH = YEAR / 12;

const formats = [
  [ 0.7 * MINUTE, 'just now' ],
  [ 1.5 * MINUTE, '1m ago' ],
  [ 60 * MINUTE, 'm ago', MINUTE ],
  [ 1.5 * HOUR, '1h ago' ],
  [ DAY, 'h ago', HOUR ],
  [ 2 * DAY, 'yesterday' ],
  [ 7 * DAY, 'd ago', DAY ],
  [ 1.5 * WEEK, '1 wk ago'],
  [ MONTH, 'wks ago', WEEK ],
  [ 1.5 * MONTH, '1 month ago' ],
  [ YEAR, 'months ago', MONTH ],
  [ 1.5 * YEAR, '1 yr ago' ],
  [ Number.MAX_VALUE, 'yrs ago', YEAR ]
];

const len = formats.length;

export default function relativeDate(input,reference){
  if (!reference) {
    reference = new Date().getTime();
  }

  if (reference instanceof Date) {
    reference = reference.getTime();
  }

  if (input instanceof Date) {
    input = input.getTime();
  }

  const delta = reference - input;

  for(let i = 0; i < len; i++){
    const format = formats[i];
    if(delta < format[0]){
      return format[2] === undefined ? format[1] : Math.round(delta/format[2]) + ' ' + format[1];
    }
  };
}
