import { useMemo, useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, VSeparator, Opaque, HFlex, VFlex, BigLoader } from '../components/Layout';
import MiniCupStake from '../components/MiniTeam.jsx';
import { observer } from 'mobx-react-lite';
import { sendCollectTxns, sendDraw1Txns } from '../algo.js';
import { account, history, assets, localAssets, localAppState, globalAppState } from '../state/';
import Play from '../components/Play.jsx';
import Wallets from '../components/Wallets.jsx';
import NFTs from '../components/NFTs.jsx';
import AllNFTTable from '../components/AllNFTTable.jsx';
import AllNFTs from '../components/AllNFTs.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { YourHistory, GlobalHistory } from '../components/History.jsx';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { afterRoyalT, ALGO } from '../constants.js';

const collectionUrl = 'https://exa.market/collection/cupstakes2022';

const activeRoyalT = Date.now() < afterRoyalT;

const hrs48 = Date.now() + 48 * 60 * 60 * 1000;

function combinations(array) {
  return new Array(1 << array.length).fill().map(
    (e1, i) => array.filter((e2, j) => i & 1 << j)
  );
}

function showUnit(unit) {
  const u = unit.toLowerCase();
  switch(u) {
    case 'algo': return ALGO;
    case 'usdc': return '$';
    case 'galgo': return `g{$ALGO}`;
    default: return unit;
  }
}

function getCheapestOffer(id, desiredQ, relevantEntries) {
  const offers = [];
  let totalSupplied = 0;
  for(const entry of relevantEntries) {
    const { appId, forSale: { [id]: suppliedQ }, forPrice: { amount, unit } } = entry;
    offers.push([suppliedQ, amount, unit, appId]);
    totalSupplied += suppliedQ;
  }
  if (totalSupplied < desiredQ)
    return Infinity;
  const combos = combinations(offers);
  for(const comb of combos) {
    const [totalQ, totalPrice, appIds] = comb.reduce((out, [q, amt, unit, appId]) => {
      out[0] += q;
      const price = out[1];
      const unitPrice = price.find(p => p?.unit === unit);
      if (!unitPrice) {
        const price = { unit, amt };
        out[1].push(price);
      } else {
        out[1].amt += amt;
      }
      out[2].push(appId);
      return out;
    }, [0, []]);
    if (totalQ < desiredQ)
      break;
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cp-data-tabpanel-${index}`}
      aria-labelledby={`cp-data-tab-${index}`}
      {...other}
    >
      {value === index && (<>
          {children}
      </>)}
    </div>
  );
}

function a11yProps(_id) {
  const { id } = tabs.find(({id}) => id === _id);
  return {
    id,
    'aria-controls': `cp-tabpanel-${id}`,
  };
}

const tabs = [
  { id: "buy", title: "Buy", route: "/trade/#buy", },
  { id: "sell", title: "Sell", route: "/trade/#sell", },
]

function SellRow({ i, cupStake, line, buttons }) {
  return <HFlex key={`sell-${i}`} sx={{ width: 1, justifyContent: { xs: 'center', md: 'space-between' }, alignItems: { xs: 'center', md: 'flex-start' }, mb: 2, fiexDirection: { xs: 'column', md: 'row' }}}>
    { cupStake }
    <Opaque op={0.95} sx={{flexDirection: 'row', pt: { xs: 5, md: 3 }, pr: 3, flexGrow: 1, mb: { xs: 4, md: 0 }, mt: { xs: '-24px', md: 0 }}}>
      <VFlex sx={{ flexGrow: 1, alignItems: 'flex-start' }}>{line}</VFlex>
      <VFlex sx={{alignSelf: 'stretch', justifyContent: 'space-between'}}>
        {buttons}
      </VFlex>
    </Opaque>
  </HFlex>;
}

function sellLink(id, quantity) { return `https://exa.market/create/basket?buyer=&forSaleAssets=%5B%7B%22asset%22%3A${id}%2C%22quantity%22%3A${quantity}%2C%22index%22%3A14%7D%5D&priceQuantity=` }

function TradeHeader({ title }) {
  const text = !activeRoyalT ? 'Royalty payments will be added to the rewards pool!' : <strong>Royalty payments until Dec 9th are part of the <Link href="/homesend">HOMESEND Royal-T challenge prize!</Link></strong>

  return <Opaque>
    <Typography align="center" sx={{mb: 2}} variant="h3">{title}</Typography>
    <Typography sx={{ mb: { xs: 1, md: 0 } }}>We are proud to have partnered with <Link href={collectionUrl} >EXA</Link> as our official secondary market.</Typography>
    <Typography>{text}</Typography>
    <Typography>Royalties are set to 10%.</Typography>
  </Opaque>;
}

const Sell = observer(function({ account, localAssets, assets, exa, doNotShowAgain, fsm, setModal }) {
  const sell = useCallback((id, quantity) => {
    let link = sellLink(id, quantity);
    doNotShowAgain.renderAwait({
      uniqueKey: 'sell',
      title: 'Selling on EXA',
      explainer: <>
        <Typography>You will now be taken to the Exa marketplace to list your CupStake.</Typography>
        <Typography>Sales include a 10% royalty fee. These payments will fund the Royal-T challenge until December 9th, and after that they will be sent to the rewards pool.</Typography>
        <Typography>Please double-check your items and quantities before listing.</Typography>
        <Typography><strong>If you are selling multiple items, the price is for ALL of them, not the individual one.</strong></Typography>
      </>,
      callback: () => window.open(link),
      setModal,
    });
  }, []);

  return <>
    <TradeHeader title="SELL on EXA" />
    <VSeparator />
    {
      !account.address ? <Opaque sx={{ width: 1 }} vh={45}>
        <VFlex>
          <Typography align="center" sx={{ mb: 3 }} variant="h3">CONNECT WALLET</Typography>
          <Wallets account={account} fsm={fsm} />
        </VFlex>
      </Opaque> : null
    }

    <VFlex sx={{ width: 1, minHeight: '50vh' }}>
      <VFlex sx={{ width: { xs: 1, md: 0.8, xl: 0.7 }}}>
        { localAssets.entries() // TODO sort by rank, whatever
            .filter(([id, { amount }]) => amount && id !== 'free')
            .map(([id, { amount }], i) => {
              const asset = assets.get(id);
              return { id, amount, ...asset }
        }).sort((a, b) => a.rank < b.rank ? -1 : 1)
          .map(({ id, isAuction, amount, name, code, odds, fillColor }, i) => {
            const { totalQuantity, relevantEntries, fromPrice, baskets } = exa.getASAEntries(id);
        let extra = <>Be the first to list a {name} CupStake!</>;
          const rel = totalQuantity ? relevantEntries
                .sort(({forPrice: { amount: a }}, {forPrice: {amount: b}}) => a < b ? -1 : 1)
                : [];
        if (totalQuantity) {
          extra = <>
            {                 rel.map(({forSale, isAuction, forPrice: { amount, unit } }, i) => 
            <Typography key={i}>{forSale[id]}x {name}{isAuction ? ' at':' for'} {showUnit(unit)}{amount} {isAuction ? '🔨':''}</Typography>
            ) }
            <Button onClick={() => window.open(`https://exa.market/asset/${id}`)}>SEE ALL</Button>
          </>;
        }
          const sellBtn = []
          if (amount > 1) {
            sellBtn.push(<Button variant="outlined" onClick={() => sell(id, 1)}>SELL {1}x</Button>);
          }
            sellBtn.push(<Button variant="outlined" onClick={() => sell(id, amount)}>SELL {amount}x</Button>);
          return <SellRow
            i={i}
            cupStake={<MiniCupStake code={code} disqualified={odds === Infinity ? fillColor : null} />}
            line={extra}
            buttons={sellBtn}
          />;
          })
          }
        </VFlex>
      </VFlex>
    </>;
});

const Buy = observer(function({ account, localAssets, assets, exa, fsm, doNotShowAgain, setModal, viewBtn, hashEnd }) {
   const buy = useCallback((id, appId) => {
   let link = `https://exa.market/asset/${id}${appId ? '?basket='+appId : ''}`
    doNotShowAgain.renderAwait({
      uniqueKey: 'BUY',
      title: 'Buying on Exa',
      explainer: <>
        <Typography>You will now be taken to the Exa marketplace to browse available CupStakes listed by other users.</Typography>
        <Typography>Sales include a 10% royalty fee. This royalty is included in the price you see.</Typography>
        <Typography>These payments will fund the Royal-T challenge until December 9th, and after that they will be sent to the rewards pool.</Typography>
        { appId ? null : <Typography><strong>Select the <u>Listings</u> tab to see all available listings.</strong></Typography> }
      </>,
      callback: () => window.open(link),
      setModal,
    });
  }, []);

  let initView = hashEnd ? hashEnd.split(':')[0] : account.address ? 'homesend' : 'qualifying'
  if (!account.address && initView === 'homesend')
      initView = 'qualifying';
  if (!activeRoyalT && initView === 'homesend') {
      initView = 'qualifying';
    }
  const [viewfilter, setViewfilter] = useState(initView);

  const handleViewfilter = (event, newViewfilter) => {
    if (newViewfilter === 'homesend' && !account.address) {
      alert('You need to connect your wallet to show your HOMESEND view');
      return
    }
    setViewfilter(newViewfilter);
  };

  const isHomesend = viewfilter === 'homesend';

  const data = useMemo(() => assets.entries()
        .map(([id, props], i) => {
          const exaData = exa.getASAEntries(id);
          let more = {};
          if (isHomesend) {
            const la = localAssets.get(id);
            const amt = la?.amount ?? 0;
            more.amt = amt;
            more.needs = props.available ? Infinity : (3 - amt) % 3 || 3;
          }
          return { ...props, ...exaData, ...more };
        })
        .sort((a, b) => {
          if (isHomesend) {
            const ap = a.fromPrice || Infinity;
            const an = a.needs;
            const bp = b.fromPrice || Infinity;
            const bn = b.needs;
            if (an === bn)
              return ap < bp ? -1 : 1;
            return an < bn ? -1 : 1;
          }
          return a.rank < b.rank ? -1 : 1;
        })
        .filter(({ totalQuantity, available }) => {
          if (viewfilter === 'qualifying')
            return totalQuantity > 0 && available;
          if (isHomesend)
            return !available && totalQuantity;
          return true;
        }),
    [exa.hash, localAssets.length, assets.length, viewfilter, account.address]
  );
  return <>
    <TradeHeader title="BUY on EXA" />
    <VSeparator />
    <VFlex sx={{ width: 1 }}>
      <VFlex sx={{ width: { xs: 1, md: 0.8, xl: 0.7 }}}>
        <Opaque sx={{ width: 1, mb: 4 }}>
          <ToggleButtonGroup
            value={viewfilter}
            exclusive
            onChange={handleViewfilter}
            aria-label="view filter"
            color="primary"
          >
            { activeRoyalT ? 
            <ToggleButton value="homesend" aria-label="Show homesend">
              SHOW{viewfilter === "homesend"?'ING':''} HOMESEND
            </ToggleButton> : null }
            <ToggleButton value="qualifying" aria-label="Show qualifying">
              SHOW{viewfilter === "qualifying"?'ING':''} QUALIFYING
            </ToggleButton>
            <ToggleButton value="all" aria-label="Show all">
              SHOW{viewfilter === "all"?'ING':''} ALL
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography sx={{mt: 2}}>Showing { viewfilter === "homesend" ? <>the best options to complete <Link href="/homesend">HOMESEND</Link> triplets</> : viewfilter === "qualifying" ? 'available & qualifying CupStakes' : 'all CupStakes' }</Typography>
        </Opaque>
        { data.map(({ id, name, code, odds, needs, fillColor, relevantEntries, totalQuantity, fromPrice, baskets }, i) => {
        let extra;
          const rel = totalQuantity ? relevantEntries
                .sort(({forPrice: { amount: a }}, {forPrice: {amount: b}}) => a < b ? -1 : 1)
                : [];
        if (totalQuantity) {
          extra = <>
            {                 rel.map(({forSale, isAuction, forPrice: { amount, unit } }, i) => 
            <Typography key={i}>{forSale[id]}x {name}{isAuction ? ' at':' for'} {showUnit(unit)}{amount} {isAuction ? '🔨':''}</Typography>
            ) }
            { isHomesend && Number.isFinite(needs) ? <Typography sx={{mt:1}}>✈️ <em>You need {needs < 3 ? needs + " more" : needs} to HOMESEND</em></Typography> : null }
          </>;
        }
        let buttons = [];
        if (rel.length) {
          const { isAuction, appId, forPrice: { unit, amount }, forSale: { [id]: quantity } } = rel[0];
        buttons.push(<Button variant="outlined" onClick={() => buy(id, appId)}>{isAuction?'BID':'BUY'} {quantity}x {showUnit(unit)}{amount}</Button>)
        }
        buttons.push(<Button onClick={() => buy(id)}>{rel.length ? 'SEE ALL' : 'CHECK EXA'}</Button>);
        return <SellRow
          i={i}
          cupStake={<MiniCupStake code={code} disqualified={odds === Infinity ? fillColor : null} />}
          line={extra}
          buttons={buttons}
        />
        }) }
      </VFlex>
    </VFlex>
  </>
});

function Data({ account, fsm, localAssets, assets, doNotShowAgain, exa }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(null);
  const [hashEnd, setHashEnd] = useState();

  const value = useMemo(() => {
    const def = 0;
    const { hash } = location;
    if (hash && hash.startsWith('#')) {
      const [hashStart, hashEnd] = hash.split(':');
      if (hashEnd)
        setHashEnd(hashEnd);
      const idx = tabs.findIndex(tab => tab.route.endsWith(hashStart));
      return idx ?? def
    }
    return def;
  }, [location.hash, account.address]);

  const handleChange = (event, newValue) => {
    const { route } = tabs[newValue];
    navigate(route);
  };


  return <>
    {modal}
    <VSeparator id="data" />

    <Opaque>
      <Typography align="center" id="#table" sx={{textTransform: 'none'}} variant="h2">CUPTRADES</Typography>
      <HFlex>
        <Tabs
          value={value} 
          onChange={handleChange}
          aria-label="Data navigation" sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
          },
          }}>
          {tabs.map(({ id, title, hide }) =>
            hide ? null : <Tab key={`tabhead-${id}`} label={title} {...a11yProps(id)} />
          )}
        </Tabs>
      </HFlex>
    </Opaque>

    <VSeparator />

    <TabPanel value={value} index={1}>
      <Sell account={account} localAssets={localAssets} fsm={fsm} assets={assets} exa={exa} doNotShowAgain={doNotShowAgain} setModal={setModal} />
      
    </TabPanel>

    <TabPanel value={value} index={0}>
      <Buy account={account} localAssets={localAssets} fsm={fsm} assets={assets} exa={exa} doNotShowAgain={doNotShowAgain} setModal={setModal} hashEnd={hashEnd} />
    </TabPanel>

    <VSeparator />
  </>
}


/* Your History */
/* Leaderboard */
/* All Actions */
/* Odds */
/* Analytics */

export default observer(Data);

