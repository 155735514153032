import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import HourglassIcon from './hourglass-icon.jsx';
import { HFlex } from '../components/Layout.jsx';

export default function TxnButton({ pending, tooltip, icon, children, disabled: _disabled, ...props }) {
  const [disabled, setDisabled] = useState();
  const [Icon, setIcon] = useState(icon);
  const variant = props['variant'] ?? 'outlined';
  const onClick = useCallback(async () => {
    console.log('a');
    setIcon(<HourglassIcon />);
    setDisabled(true);
    try {
      await props.onClick();
    } catch(e) {
    } finally {
      console.log('b');
      setDisabled(false);
      setIcon(icon);
    }
  }, [props.onClick]);
  const sx = {
    minWidth: '320px',
    mt: 0.5,
    mb: 0.5,
    ...props.sx,
  }
  return <HFlex><Button {...props} sx={sx} className="txn-button" variant={variant} disabled={disabled || _disabled} onClick={onClick} startIcon={Icon}>
    {children}
  </Button>{tooltip}</HFlex>;
}
