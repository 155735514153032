
// subscribe(apiPath, { 'data': dataCallback, 'round': roundCallback })
// unsubscribe(apiPath)

let eventSource;

export function subscribe(apiPath, callback) {
  console.log('Subscribing to', apiPath);
  if (eventSource) {
    // close if different url
    if (!eventSource.url.endsWith(apiPath)) {
      console.warn('EventSource url changed without unsubscribe');
      eventSource.close();
    } else {
      console.warn(`Re-subscribing to ${apiPath}`);
    }
  }
  createEventSource(apiPath, callback);
}

const backends = [
  'https://exa.cupstakes.world',
  'https://exa2.cupstakes.world',
];

let backendIndex = 0;
let activeBackend;

function createEventSource(apiPath, callback, errors=0) {
  // const expoBackOff = new ExpoBackOff();
  const absoluteApiPath = makeAbsoluteApiPath(apiPath);

  console.log(`Connecting to ${apiPath}`);
  eventSource = new EventSource(absoluteApiPath);
  let ok = true;

  eventSource.onerror = (err) => {
    if (!ok) {
      console.warn('Ignoring in event-stream', err.message);
      return;
    }
    ok = false;
    eventSource.close();
    backendIndex++;
    errors++
    const tm = Math.min(30_000, 1000 * Math.pow(errors, 2));
    console.error(`Backend disconnected. Reconnecting after ${tm}ms.`, err.message ?? err);
    setTimeout(() => {
      createEventSource(apiPath, callback, errors);
    }, tm);
  };

  eventSource.onopen = () => {
    activeBackend = getBackendFromIndex();
    console.log(`Backend connected.`, activeBackend);
    eventSource.addEventListener('message', ({data}) => callback(data));
    const initErrors = errors;
    setTimeout(() => { if (errors === initErrors) errors = 0 }, 15_000);
  }

  return eventSource;
}

function getBackendFromIndex(idx = backendIndex) {
  return backends[backendIndex % backends.length];
}

function makeAbsoluteApiPath(apiPath) {
  return `${getBackendFromIndex()}${apiPath}`;
}

export function unsubscribe(apiPath) {
  if (eventSource) {
    // close if different url
    if (!apiPath || eventSource.url.endsWith(apiPath)) {
      console.log('Unsubscribing from ', eventSource.url);
      eventSource.close();
      eventSource = null;
    } else {
      console.warn(`Requested unsubscribe from ${apiPath} but was subscribed to ${eventSource.url} instead`);
      eventSource.close();
      eventSource = null;
    }
  } else {
    console.warn(`Requested unsubscribe from ${apiPath} but was not subscribed to anything`);
  }
}

export function getSubscriptionApiPath() {
  return eventSource?.url;
}

const exports = { subscribe, unsubscribe, getSubscriptionApiPath };

export default exports;
