import { useMemo, useState, useEffect, useCallback } from 'react';
import Tooltip from '../components/Tooltip.jsx';
import { numLocaleOptionsFull } from '../constants.js';
import config from '../constants.json';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, VSeparator, Opaque, HFlex, VFlex, BigLoader, Left, } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { sendVote } from '../algo.js';
import { fsm } from '../state/';
import Play from '../components/Play.jsx';
import Wallet from '../components/Wallets.jsx';
import NFTs from '../components/NFTs.jsx';
import AllNFTTable from '../components/AllNFTTable.jsx';
import AllNFTs from '../components/AllNFTs.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TxnId, TxnAddress } from '../components/History.jsx';
import { appParamUpdates, getVoteTxns, spendStats } from '../algo/indexer.js';
import { TableRow as RTableRow, TableCell as RTableCell } from '../components/responsive-table.js'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';
import useInterval from '../hooks/use-interval.js';
import { govEnd } from '../constants.js';

const afterGov = govEnd * 1000;

function makeExplorerTxnLink(id, explorer = 'algoexplorer.io') {
  const host = config.network === 'testnet' ? `testnet.${explorer}` : explorer;
  return `https://${host}/tx/${id}`;
}

function optionNumToLetter(num) {
  return String.fromCharCode(64 + Number(num));
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', ...props.outerSx}}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const measureAnswersLabel = [
  {
    A: "2 months",
    B: "3 months",
    C: "4 months",
  },
  {
    A: "EFF",
    B: "FFL",
    C: "GEKO",
  }
];

const choices = measureAnswersLabel.map((measureAnswers) => {
  return Object.keys(measureAnswers);
});

function validateVotes(votes) {
  if (!votes || votes.length !== 2 || !votes.every((choice, i) => choices[i].includes(choice))) {
    return false
  }
  return true;
}

function parseVotes(txns, spendingPowerAll) {
  const results = [{A:0,B:0,C:0,all:0},{A:0,B:0,C:0,all:0},];
  const voted = new Set();
  for(const txn of txns) {
    const [address, votes, ts] = txn;
    if (!validateVotes(votes)) {
      txn[1] = false;
      continue;
    }
    if (!spendingPowerAll[address]) {
      txn[1] = false;
      continue
    }
    if (voted.has(address)) {
      console.log('address', address, 'voted more than once');
      continue;
    }
    for(let i=0; i<votes.length; i++) {
      const vote = votes[i];
      const power = spendingPowerAll[address];
      results[i][vote] += power;
      results[i].all += power;
      voted.add(address);
    }
  }
  return results;
}

const MIN = 60 * 1000;
const HOUR = MIN * 60;
const DAY = HOUR * 24;

function DateDiff({ to, end='Challenge Expired', startLabel='Ends in: ' }) {
  const [label, setLabel] = useState('');
  const [ended, setEnded] = useState(false);

  function calcLabel(value) {
    const now = Date.now();
    const then = to;
    const diff = then - now;
    if (diff > 0) {
      const dayDiff = Math.floor(diff / DAY);
      let hours = dayDiff ? diff - dayDiff * DAY : diff;
      const hourDiff = Math.floor(hours / HOUR);
      let minutes = hourDiff ? hours - hourDiff * HOUR : hours;
      const minuteDiff = Math.floor(minutes / MIN);
      const dateLabel = dayDiff > 0 ? `${dayDiff} Day${dayDiff != 1 ? 's' : ''} ` : '';
      setLabel(`${dateLabel}${hourDiff} Hrs ${minuteDiff} Min`);
    } else if (!ended) {
      setEnded(true);
    }
  }
  useEffect(() => calcLabel(to), [to]);
  useInterval(() => calcLabel(to), 60_000);
  return ended ? <Typography className="glow2" variant="h2">{end}</Typography>
    : <Typography className="glow2" variant="h3">{startLabel}{label}</Typography>
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cp-data-tabpanel-${index}`}
      aria-labelledby={`cp-data-tab-${index}`}
      {...other}
    >
      {value === index && (<>
          {children}
      </>)}
    </div>
  );
}

function a11yProps(_id) {
  const { id } = tabs.find(({id}) => id === _id);
  return {
    id,
    'aria-controls': `cp-tabpanel-${id}`,
  };
}

const tabs = [
  { id: "vote", title: "Vote", route: "/gov/#vote", },
  { id: "results", title: "Results", route: "/gov/#results", },
]


function Gov({ account, history }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [results, setResults] = useState([{}, {}]);
  const [power, setPower] = useState();
  const [yourVotes, setYourVotes] = useState([]);
  const [spendingPowerAll, setSpendingPowerAll] = useState({});
  const [loading, setLoading] = useState(true);

  const value = useMemo(() => {
    const def = 1;
    const { hash } = location;
    if (hash && hash.startsWith('#')) {
      const idx = tabs.findIndex(tab => tab.route.endsWith(hash));
      return idx ?? def
    }
    return def;
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    const { route } = tabs[newValue];
    navigate(route);
  };

  const [measure1, setMeasure1] = useState(null);
  const [measure2, setMeasure2] = useState(null);

  const changeMeasure1 = (e) => {
    setMeasure1(e.target.value);
  }

  const changeMeasure2 = (e) => {
    setMeasure2(e.target.value);
  }

  const canVote = !!measure1 && !!measure2 &&account.address && !loading && power;

  const vote = async () => {
    await sendVote(measure1, measure2);
    setYourVotes([measure1, measure2]);
    window.scroll(0, 0);
    setTimeout(() => history.getGlobalHistory(), 3_000);
  }

  useEffect(() => {
    history.getGlobalHistory();
  }, []);

  useEffect(() => {
    const spendingPowerAll = spendStats(history.data);
    setSpendingPowerAll(spendingPowerAll);
  }, [history.lastUpdated]);

  useEffect(() => {
    (async() => {
      if (account.address) {
        if (!history.data?.length)
          return;
        const totalSpend = spendingPowerAll.all;
        const power = (spendingPowerAll[account.address] ?? 0);
        const powerPerc = (100 * power / totalSpend).toFixed(2);
        setPower(power ? `${power.toFixed(2)} (${powerPerc}%)` : 0);
      } else {
        setPower(0);
      }
    })()
  }, [account.address, spendingPowerAll]);

  const [votes, setVotes] = useState([]);

  const isGovActive = Date.now() < afterGov;

  useEffect(() => {
    (async() => {
      if (Object.keys(spendingPowerAll).length > 1) {
        const v = await getVoteTxns()
        setResults(parseVotes(v, spendingPowerAll));
        setVotes(v);
        const voters = new Set(v.map(([addr, votes]) => { if (votes) return addr }).filter(Boolean));
        const yourVotes = parseVotes(v.filter(([addr]) => addr === account.address), spendingPowerAll);
        if (yourVotes.length && yourVotes[0].all) {
          const yv = Object.values(yourVotes).map((votes, measure) => {
            const opt = Object.entries(votes).find(([option, votes]) => votes)[0];
            return opt;
          });
          setYourVotes(yv);
        } else {
          setYourVotes([]);
        }
        setLoading(false);
      }
    })()
  }, [account.address, spendingPowerAll]);


  const voteHistory = useMemo(() => {
    const voted = new Set();
    return votes.map(([address, votes, time, id], i) => {
      const invalid = voted.has(address);
      const voterPower = spendingPowerAll[address];
      if (!voterPower) return null;
      const voterPerc = 100 * voterPower / spendingPowerAll.all;
      voted.add(address);
      const measureVotes = votes ? votes.map((v, i, a) => <span>M{i+1}: {v}{i+1 < a.length ? ', ' : ''}</span>) : '(invalid)';
      const measureVoteWrap = invalid ? <span style={{textDecoration: 'line-through'}}>{measureVotes}</span> : measureVotes;
      return <HFlex sx={{flexDirection: { xs: 'column', md: 'row'} }} key={i}>
        <Box sx={{ display: { xs: 'display', md: 'none', }, mt: 2}}>💌</Box>
        <span>{new Date(time*1000).toLocaleString()}&nbsp;</span>
        <TxnAddress search={false} sx={{display: 'inline-flex', ml: 1}} address={address} />
        <span>&nbsp;({voterPerc.toFixed(2)}% stake)&nbsp;</span>
        <Link href={makeExplorerTxnLink(id)}>voted {measureVoteWrap}</Link>
        {invalid ? <span>&nbsp;(not final)</span> : null }
      </HFlex>
    });
  }, [votes]);

  return <>
    <VSeparator id="data" />

    <Opaque>
      <Typography align="center" id="#table" variant="h2">GOVERNANCE</Typography>
      <HFlex>
        <Tabs
          value={value} 
          onChange={handleChange}
          aria-label="Data navigation" sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
          },
          }}>
          {tabs.map(({ id, title, }) =>
            <Tab key={`tabhead-${id}`} label={title} {...a11yProps(id)} />
          )}
        </Tabs>
      </HFlex>
    </Opaque>

    <VSeparator />
    <VFlex>
      <DateDiff pre="Ends in: " to={afterGov} end="Voting Period Ended" />
    </VFlex>
    <VSeparator />

    <TabPanel value={value} index={0}>
      { isGovActive ? <>
        <Opaque sx={{pb: 2}}>
        <Typography variant="h3">CupStakes Governance Vote</Typography>
      </Opaque>
        <VSeparator vh={1} />
        <Opaque sx={{pb: 2}}>
        <Left>
          <Typography>We invite you to vote on specific details of the Rewards Distribution.</Typography>
          <Typography>Your voting power is proportional to your Spend on CupStakes.</Typography>
          <Typography>Voting works on-chain, like the familiar Algorand Foundation voting.</Typography>
        </Left>
      </Opaque>
      <VSeparator vh={1} />
      { !account.address ? null : <Opaque>
          <Typography variant="h3">Your Voting Power</Typography>
        <Typography><strong>Voting Power: {typeof power === 'undefined' ? '<Loading>' : power || 'None. You are not eligible to vote.'}</strong></Typography>
        { yourVotes.length ? <>
          <Typography variant="h4" sx={{mt: 2}}><strong>You voted successfully!</strong></Typography>
          <Left>
          { Object.entries(yourVotes).map(([measure, vote], i) => 
            <Typography><strong>Measure {i+1}:</strong> Option {vote} - {measureAnswersLabel[i][vote]}</Typography>
          ) }
          </Left>
          <Button sx={{mt: 2}} variant="outlined" onClick={() => navigate("/gov/#results")}>VIEW RESULTS</Button>
        </>
          : null }
      </Opaque>
      }

      <VSeparator vh={1} />

      </> : null }

      <Opaque>
        <Typography variant="h3">Measure 1</Typography>
        <Left sx={{minWidth: { xs: 0, md: '530px' }}}>
          <Typography sx={{mt: 1, mb: 1}}><strong>Length of Rewards Distribution period</strong></Typography>
          <Typography>How long should the rewards distribution period be?</Typography>
          <Typography sx={{mb: 1}}>After this period, unclaimed rewards will be donated to a charity.</Typography>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Rewards Distribution Period</FormLabel>
            <RadioGroup
              aria-labelledby="measure-1-rewards-distribution-length"
              name="radio-buttons-group"
              value={measure1}
              onChange={changeMeasure1}
            >
              <FormControlLabel value="A" control={<Radio />} label="Option A: 2 months (recommended)" />
              <FormControlLabel value="B" control={<Radio />} label="Option B: 3 months" />
              <FormControlLabel value="C" control={<Radio />} label="Option C: 4 months" />
            </RadioGroup>
          </FormControl>
        </Left>
      </Opaque>

      <VSeparator vh={1} />

      <Opaque>
        <Typography variant="h3">Measure 2</Typography>
        <Left sx={{minWidth: { xs: 0, md: '530px' }}}>
          <Typography sx={{mt: 1, mb: 1}}><strong>Charity Recipient of Unclaimed Rewards</strong></Typography>
          <Typography>Which charity should receive unclaimed rewards?</Typography>
          <Typography>The recipient will receive the rewards trustlessly from the smart contract.</Typography>
          <Typography sx={{mb: 1 }}>CupStakes will not intermediate this payment.</Typography>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Unclaimed rewards charity</FormLabel>
            <RadioGroup
              aria-labelledby="measure-2-rewards-distribution-length"
              name="radio-buttons-group"
              value={measure2}
              onChange={changeMeasure2}
            >
              <FormControlLabel value="A" control={<Radio />} label={<Left>
                <HFlex sx={{mt: 2}}><span>Option A:&nbsp;</span><Link href="https://eff.org">Electronic Frontier Foundation</Link></HFlex>
                <HFlex><em>Defending digital privacy, free speech, and innovation.</em></HFlex>
              </Left>} />
              <FormControlLabel value="B" control={<Radio />} label={<Left>
                <HFlex sx={{mt: 2}}><span>Option B:&nbsp;</span><Link href="https://ffl.org">Food for Life</Link></HFlex>
                <HFlex><em>The world's largest plantbased food relief charity.</em></HFlex>
              </Left>} />
              <FormControlLabel value="C" control={<Radio />} label={<Left>
                <HFlex sx={{mt: 2}}><span>Option C:&nbsp;</span><Link href="https://www.gekofam.com/charity">Gekofam & Friends</Link></HFlex>
                <HFlex><em>Enrich the lives of special needs children by giving back.</em></HFlex>
              </Left>} />
            </RadioGroup>
          </FormControl>
        </Left>
      </Opaque>

      <VSeparator vh={1} />

    { isGovActive && !account.address ? 
        <Opaque>
          <Typography sx={{mb: 1}} variant="h3">Connect Wallet to Vote</Typography>
          <Wallet account={account} fsm={fsm} />
        </Opaque>
      :
      <Opaque>
        <Left>
          { isGovActive ? 
          <VFlex sx={{width: 1}}>
            { power ? <Button variant="outlined" onClick={vote} disabled={!canVote} size="big">{ account.address ? (yourVotes.length ? 'CHANGE VOTE' : 'VOTE') : 'NOT CONNECTED' }</Button> : <Typography><strong>{loading ? 'Loading' : 'You can not vote'}</strong></Typography> }
          </VFlex> :
            <VFlex>
              <strong>Voting period has ended.</strong>
              <Button sx={{mt: 2}} variant="outlined" onClick={() => navigate("/gov/#results")}>VIEW RESULTS</Button>
            </VFlex>
          }
        </Left>
      </Opaque>
      }
    </TabPanel>

    <TabPanel value={value} index={1}>
      <Opaque sx={{pb: 2}}>
        <Typography variant="h3">CupStakes Governance Results</Typography>
      </Opaque>
      <VSeparator vh={1} />
      <Opaque>
        { loading ? <span><strong>Loading</strong></span> : <>
          <VFlex sx={{mb: 1}}>
            <Typography variant="h4" sx={{mb: 0}}>Stake Voted: {results[0].all?.toFixed(2)} / {spendingPowerAll.all.toFixed(2)}</Typography>
            <LinearProgressWithLabel sx={{width: 1}} variant="determinate" value={results[0].all * 100 / spendingPowerAll.all} />
          </VFlex>
          <HFlex sx={{ width: { xs: 1, md: 0.7, lg: 0.6 }, justifyContent: 'space-around' }}>
            {results.map((r, m) =>
            <VFlex sx={{mb: 1, mt: 1}}><Typography variant="h4">Measure {m+1}</Typography>
              <Left>{Object.entries(r).map(([result, votes], i, all) => {
                if (result === 'all') return null;
                const perc = (votes / r.all * 100).toFixed(2)
                const label = measureAnswersLabel[m][result];
                return <VFlex sx={{width: 1, minWidth: '250px', alignItems: 'flex-start'}}>
                  <Typography sx={{mb: '-3px', mt: 1.5}} align="left">Option {result} - {label}: {votes.toFixed(2)}</Typography>
                  <LinearProgressWithLabel sx={{width: 1}} variant="determinate" value={perc} />
                </VFlex>
                })}</Left>
            </VFlex>
            )}
          </HFlex>
          </>
        }
      </Opaque>

      <VSeparator />

      <Opaque>
        <Typography variant="h3" sx={{mb: 1}}>Voting History</Typography>
        { loading ? <span><strong>Loading</strong></span> : null }
        <Left>
          { voteHistory }
        </Left>
      </Opaque>
    </TabPanel>

    <VSeparator />
  </>
}

/* Your History */
/* Leaderboard */
/* All Actions */
/* Odds */
/* Analytics */

export default observer(Gov);

