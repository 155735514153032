import { useEffect } from 'react';
import images from './cupstakes-images.js';
import imagesHigh from './cupstakes-images-high.js';

function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = function() {
      resolve(img)
    }
    img.onerror = function(err) {
      console.error('img error', src, err);
      resolve();
    }
    img.onabort  = function() {
      console.log('img aborted', src);
      img.src = src
      resolve();
    }
  })
}

export default function Preload({ high, codes, delay, onLoad }) {
  delay = delay ?? 1000;
  let sources = high ? imagesHigh : images;
  sources = codes?.length ?
    Object.entries(sources).filter(([key]) => key === "back" || codes.includes(key)).map(([_, val]) => val)
    : Object.values(sources);
  useEffect(() => {
    setTimeout(async () => {
      await Promise.all(sources.map((src, i) => preloadImage(src)));
      if (onLoad) {
        onLoad();
      }
    }, delay);
  }, []);
  return <></>;
}
