import { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { optoutApp, projectTimeForRoundAsync, refreshAppLocalState, sendExecTxns, refreshBalances } from '../algo.js';
import Countdown from './Countdown.jsx';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { HFlex, VFlex, VSeparator, Opaque } from './Layout.jsx';
import notifications from '../notifications.js';
import DiceIcon from '@mui/icons-material/Casino';
import TxnButton from './txn-button.js';
import CopyButton from './CopyButton.jsx';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const RDIFF = 4;

function ExecuteBtn() {
  return <TxnButton icon={<DiceIcon />} onClick={sendExecTxns}>MANUALLY EXECUTE DRAW</TxnButton>;
}

function StaleDraw() {
  const callback = async () => {
    await optoutApp();
    window.location.reload();
  }
  return <div style={{maxWidth: '30vw'}}>
    <Typography align="center">Your Draw has gone stale</Typography>
    <br/>
    <div>Draws must be executed within 1000 rounds of being paid for, or they go stale.</div>
    <br/>
    <div>This should not have happened. Please get in touch and we will help you out:</div>
    <div><br /></div>
    <div>via Twitter <a target="_blank" href="https://twitter.com/cupstakes">@CupStakes</a></div>
    <div>via Email <a href="mailto:world@cupstakes.world">world@cupstakes.world</a> <CopyButton variant="outlined" size="small" value="world@cupstakes.world" /></div>
    <div></div>
    <div><hr /></div>
    <div>Alternatively, you can Opt Out of the contract and then Opt in again.</div>
    <div>Your ticket fee will be lost, but we will be happy to refund you manually - just let us know.</div>
    <HFlex sx={{mt: 1}}><Button variant="outlined" onClick={callback}>OPT OUT</Button></HFlex>
  </div>
}

function ManualExec() {
  // return <StaleDraw />;
  return <div style={{maxWidth: '30vw'}}>
    <Typography><strong>Drawing seems to be taking too long.</strong></Typography>
    <Typography>If this persists for more than a few minutes:</Typography>
    <ul style={{marginTop: '0px'}}><li>Check your Internet Connectivity</li>
    <li>Try to execute the draw manually</li>
    </ul>
    <HFlex><ExecuteBtn /></HFlex>
  </div>
}

function DrawWatcher({ localAppState, assets, algorand }) {
  const round = algorand.round;
  const [roundDiff, setRoundDiff] = useState(0);
  const [before, setBefore] = useState([]);
  const [countdown, setCountdown] = useState();
  const [manualExec, setManualExec] = useState();
  const [manualExecTimeout, setManualExecTimeout] = useState();


  const setFast = useCallback(() => {
    window.scroll(0, 25);
    console.log('setting fast');
    document.documentElement.style.setProperty(
      '--bg-spin-duration',
      `20s`,
    );
  }, []);

  const setSlow = useCallback(() => {
    console.log('setting slow');
    document.documentElement.style.setProperty(
      '--bg-spin-duration',
      `60s`,
    );
  }, []);

  useEffect(() => {
    (async() => {
      if (!round)
        return;
      if (localAppState.drawRound) {
        const targetRound = localAppState.drawRound + RDIFF;
        setRoundDiff(targetRound - round);
        await refreshAppLocalState();
        if (!before.length && round > targetRound + RDIFF) {
          if (round > targetRound + 1000) {
            setManualExec(<StaleDraw />)
          } else {
            setManualExec(<ManualExec />);
          }
        }
        if (!before.length) {
          const expectedTime = await projectTimeForRoundAsync(targetRound + 1);
          if (round < targetRound + RDIFF) {
            setCountdown(expectedTime - Date.now());
          }
          setFast();
          const { slot1, slot2, slot3 } = localAppState;
          setBefore([slot1, slot2, slot3]);
        }
      } else if (before.length) {
        // if before set: draw performed, display results
        setRoundDiff(0);
        const { slot1, slot2, slot3 } = localAppState;
        const changed = [slot1, slot2, slot3].filter((s, i) => {
          return s && s !== before[i];
        })
        if (changed.length) {
          setSlow();
          const str = changed.map((id, i) => {
            const { flag, name, rank, odds } = assets.get(id);
            return <HFlex key={`draw-${i}`} className="bigtext"><span style={{fontSize: '2rem'}}>{flag}</span> &nbsp; {name} / Rank: {rank}</HFlex>
          });
          notifications.default(<VFlex><strong className="maldini bigtext">YOUR DRAW{changed.length > 1 ? 'S' : ''}</strong>
            {str}
          </VFlex>, 8_000);
        }
        setBefore([]);
        setCountdown(0);
        setManualExecTimeout();
        setManualExec();
      }
    })()
  }, [round, localAppState.drawRound]);

  const countdownFinished = () => {
    setManualExecTimeout(setTimeout(() => {
      setCountdown();
      if (localAppState.drawRound)
        setManualExec(<ManualExec callback={() => setManualExec()} />);
    }, 9_000));
  }

  if (manualExec) {
    return <><Opaque>
      <Typography sx={{ fontSize: '110%' }} variant="h2">WAITING FOR DRAW</Typography>
      { countdown ? <Typography sx={{fontSize: '3rem', fontFamily: 'MaldiniStyle', }} color="primary"><Countdown onFinished={countdownFinished} suffix=" s." final="NOW" from={countdown / 100} /></Typography> : null }
      { manualExec ?? null }
    </Opaque>
      <VSeparator vh={25} /></>;
  }

  return countdown ? <>
    <Opaque className="glow" op={0}>
      <Typography align="center" color="primary" variant="h2">WAITING FOR DRAW</Typography>
      { countdown ? <Typography variant="h2" className="maldini-style"><Countdown onFinished={countdownFinished} suffix=" s." final="NOW" from={countdown / 100} /></Typography> : null }
    </Opaque>
    <VSeparator vh={25} />
    </> : null;
}

// { results ? results.map(r => { const asset = assets.get(r); return <div>{asset.code} #{asset.rank}</div> }) : null}

export default observer(DrawWatcher);
