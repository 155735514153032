import { useState, useEffect } from 'react';
import useInterval from '../hooks/use-interval.js';

export default function ({ from, final, suffix='', onFinished }) {
  const [value, setValue] = useState();
  const [displayValue, setDisplayValue] = useState();

  function format(value) {
    return (value/10).toFixed(1)+suffix;
  }

  useEffect(() => {
    setValue(from);
    setDisplayValue(format(from));
  }, [from]);

  const updateValue = () => {
    let nextValue = value - 1 > 0 ? value - 1 : 0;
    setValue(nextValue);
    if (nextValue === 0 && final) {
      setDisplayValue(final);
    } else {
      setDisplayValue(format(nextValue));
    }
    if (nextValue <= 0) {
      onFinished();
    }
  }

  useInterval(updateValue, 100);

  return <>{displayValue}</>;
}
