import { useState, useEffect } from 'react';
import { HFlex, VFlex, Logo, } from '../components/Layout.jsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Team from '../components/Team.jsx';
import Preload from '../images/cupstakes-preload.js';

export default observer(function({ assets, two }) {
  const { code, code2 } = useParams()
  const [sp] = useSearchParams()
  const spin = parseFloat(sp.get("spin") ?? 9);
  let bg = sp.get("background") ?? 'spin';
  const rotate = parseFloat(sp.get("rotate") ?? 50.7);

  const [ready, setReady] = useState();
  const [team, setTeam] = useState();
  const [team2, setTeam2] = useState();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--bg-spin-duration',
      `${spin}s`,
    );
    document.documentElement.style.setProperty('--rotate', `${rotate}deg`);
  }, [spin]);

  const al = assets.entries().length;

  useEffect(() => {
    const team = assets.values().find(({code: c}) => c === code);
    let team2;
    if (code2) {
      team2 = assets.values().find(({code: c}) => c === code2);
    }
    if (team || team2) {
      setTimeout(() =>{
        setTeam(team);
        setTeam2(team2);
      }, 1000);
    }
  }, [code, al]);

  let includeSpinBg = false;
  if (bg === "spin") {
    includeSpinBg = true;
    bg = "none";
  }

  console.log("render");

  if (!ready) {
    return <>
      <div className="loader-5 center"><span></span></div>
      <Preload high={true} codes={[code, code2]} delay={0} onLoad={() => setReady(true)} />
      { includeSpinBg ? <Logo /> : null }
    </>;
  }

  return ready && team ? <VFlex className="this" sx={{height: '100vh', width: '100%', background: `#${bg}`}}>
    { team2 ? <HFlex sx={{width: '100%', justifyContent: 'space-around'}}>
      <Team {...team} rotating high={true} />
      <Team {...team2} rotating antirotating high={true} />
    </HFlex> : 
    <Team {...team} rotating high={true} />
    }
    { includeSpinBg ? <Logo /> : null }
  </VFlex> : null;
});
