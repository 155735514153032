import RAR from './RAR.png';
import RFR from './RFR.png';
import ARG from './ARG.png';
import AUS from './AUS.png';
import BEL from './BEL.png';
import BRA from './BRA.png';
import CAN from './CAN.png';
import SUI from './SUI.png';
import CMR from './CMR.png';
import CRC from './CRC.png';
import GER from './GER.png';
import DEN from './DEN.png';
import ECU from './ECU.png';
import ENG from './ENG.png';
import ESP from './ESP.png';
import FRA from './FRA.png';
import GHA from './GHA.png';
import CRO from './CRO.png';
import IRN from './IRN.png';
import JPN from './JPN.png';
import KOR from './KOR.png';
import MAR from './MAR.png';
import MEX from './MEX.png';
import NED from './NED.png';
import POL from './POL.png';
import POR from './POR.png';
import QAT from './QAT.png';
import KSA from './KSA.png';
import SEN from './SEN.png';
import SRB from './SRB.png';
import TUN from './TUN.png';
import URU from './URU.png';
import USA from './USA.png';
import WAL from './WAL.png';
import back from './backface.png';

export default { RAR, RFR, ARG, AUS, BEL, BRA, CAN, SUI, CMR, CRC, GER, DEN, ECU, ENG, ESP, FRA, GHA, CRO, IRN, JPN, KOR, MAR, MEX, NED, POL, POR, QAT, KSA, SEN, SRB, TUN, URU, USA, WAL, back, };
