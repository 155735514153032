export function load(type, saveFn, loadEmpty = false) {
  const encoded = localStorage.getItem(type);

  if (!encoded) {
    if (loadEmpty) {
      saveFn();
    }
    return;
  }

  try {
    saveFn(JSON.parse(encoded));
  } catch(e) {
    console.log(`While loading localStorage.${type}: ${e.message}`);
  }
}

export function save(type, val) {
  console.log('saving', type, Math.floor(Date.now() / 1000));
  try {
    localStorage.setItem(type, JSON.stringify(val));
    return true;
  } catch(e) {
    console.error(`While saving localStorage.${type}: ${e.message}`);
    console.error('Value was:');
    console.error(val);
  }
}
