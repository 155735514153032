import { useState, useEffect } from 'react';
import redeem from '../redeem.json';
import { sleep } from '../utils.js';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Thanks, TeamHolder, Link, HFlex, VFlex, Left, Opaque, VSeparator } from '../components/Layout.jsx';
import Team from '../components/Team.jsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { printALGO } from '../utils.js';
import Checkbox from '@mui/material/Checkbox';
import { sendRedeem, refreshBalances } from '../algo.js';
import Wallet from '../components/Wallets.jsx';
import notifications from '../notifications.js';
import Tooltip from '../components/Tooltip.jsx';
import Images from '../images/cupstakes-images.js';

const winID = redeem.winner_asa_id;
const ruID = redeem.runner_up_asa_id;

const winShare = redeem.winner_share;
const ruShare = redeem.runner_up_share;

export default observer(function({ localAssets, assets, account, fsm }) {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const showcaseTeams = assets.entries().reduce((show, [id, data]) => {
    const { code } = data;
    if (['ARG', 'FRA'].includes(code)) {
      show[code] = data;
    };
    return show;
  }, {});

  useEffect(() => {
    (async() => {
      setLoading(true);
      await refreshBalances();
      setLoading(false);
    })()
  }, [account.address]);

  const win = assets.get(winID);
  const ru = assets.get(ruID);

  const yourWin = localAssets.get(winID)?.amount;
  const yourRU = localAssets.get(ruID)?.amount;

  const expectedReturn = (yourWin * winShare + yourRU * ruShare) / 1_000_000;

  let btnLabel = []
  let youHave = []
  let yourEarn = [];
  if (yourWin) {
    btnLabel.push(`${yourWin}x ${assets.get(winID).flag}`);
    youHave.push(`${yourWin} ${win.name} CupStake${yourWin > 1 ? 's':''}`);
    yourEarn.push(`${yourWin} x ${printALGO(winShare/1_000_000)}`);
  }
  if (yourRU) {
    btnLabel.push(`${yourRU}x ${assets.get(ruID).flag}`);
    youHave.push(`${yourRU} ${ru.name} CupStake${yourRU > 1 ? 's':''}`);
    yourEarn.push(`${yourRU} x ${printALGO(ruShare/1_000_000)}`);
  }
  let showYouHave = '';
  if (youHave.length)
    showYouHave = `You have ${youHave.join(' and ')}!`;
  else if (success)
    showYouHave = success;
  else if (loading)
    showYouHave = 'Loading';
  else
    showYouHave = 'You do not have any redeemable CupStakes.';

  yourEarn = yourEarn.join(' + ');

  if (btnLabel.length)
    btnLabel = btnLabel.join(' & ');

  const [memento, setMemento] = useState(true);

  const onChange = (e) => {
    setMemento(m => !m);
  }
  
  const [redeeming, setRedeeming] = useState(false);

  const plural = yourWin + yourRU > 1 ? 's' : '';

  const doRedeem = async (win, ru) => {
    setRedeeming(true);
    try {
      const res = await sendRedeem(yourWin, yourRU, memento);
      if (res?.length) {
        const id = res[0].id;
        notifications.success(`You successfully redeemed your CupStake${plural}!`);
        setSuccess(<VFlex>
          <Typography>You successfully redeemed {youHave.join(' and ')} for {printALGO(expectedReturn)}</Typography>
          <HFlex><Button variant="outlined" onClick={() => window.open(`https://algoscan.app/tx/${id}`)}>VIEW TRANSACTION</Button></HFlex>
        </VFlex>);
        youHave = '';
        await refreshBalances();
      }
    } catch(e) {
      console.error(e);
    }
    setRedeeming(false);
  }


  return <VFlex>
    <VSeparator />
    <Typography variant="h1">REDEEM</Typography>
    <VSeparator />
    <Opaque sx={{width: 1, pb: 2}}>
      <Left>
        <Typography variant="h3" align="center" sx={{mb: 1, width: 1}}>Redeem Winning CupStakes</Typography>
        <Typography>The 2022 World Cup winner was: {win?.name}.
        Each {win?.name} CupStake is redeemable for {printALGO(winShare/1_000_000)}</Typography>
        <Typography>The 2022 World Cup runner-up was: {ru?.name}.
        Each {ru?.name} CupStake is redeemable for {printALGO(ruShare/1_000_000)}</Typography>
      </Left>
    </Opaque>
    <VSeparator />
    <Opaque op={0} sx={{display: { xs: 'none', lg: 'flex' }, marginTop: '40px', marginBottom: '-280px', position: 'relative', width: 0.8, flexDirection: 'row', justifyContent: 'space-between'}}>
      <TeamHolder left={1} top={2}>
        <Team code="RAR" stopOnHover rotating backface={Images.ARG} />
      </TeamHolder>
      <TeamHolder top={2}>
        <Team code="RFR" stopOnHover rotating antirotating backface={Images.FRA} />
      </TeamHolder>
    </Opaque>
    <Opaque sx={{width: 1, pb: 2}}>
      { account.address ? 
      <Left>
        <Typography align="center" sx={{width: 1}} variant="h3">{ expectedReturn ? 'Woohoo!' : (loading ? 'Loading' : 'Welp') }</Typography>
        <Typography sx={{width: 1 }} align="center"><strong>{showYouHave}</strong></Typography>
        { expectedReturn ? <Left>
          <HFlex>
            <Checkbox onChange={onChange} checked={true} disabled={true} value={memento} />
            <Typography>You will receive {printALGO(expectedReturn)} ({yourEarn})</Typography>
          </HFlex>
          <HFlex>
            <Checkbox onChange={onChange} defaultChecked value={memento} />
            <Typography>{memento?'You will receive':'You will not receive'} "Redeemed" NFT{plural}
              <Tooltip extra={<Left>
                <Typography><strong>Redeemed Argentina and France CupStakes</strong></Typography>
                <Typography>You can optionally receive Redeemed (POAP) versions of your Argentina and France CupStakes.</Typography>
                <Typography>These have no monetary value, but are a memento of your participation.</Typography>
              </Left>}>You can optionally receive "Redeemed" versions of your CupStakes.</Tooltip>
            </Typography>
          </HFlex>
          <HFlex sx={{width: 1, mb: 1, mt: 1}}>
            <Button onClick={doRedeem} disabled={redeeming} size="large" variant="outlined">REDEEM {btnLabel}</Button>
          </HFlex>
        </Left> : null }
      </Left> : <>
        <Typography align="center" sx={{ mb: 3 }} variant="h3">CONNECT WALLET</Typography>
        <Wallet account={account} fsm={fsm} />
      </> }
    </Opaque>
    { account.address ? <>
      <VSeparator sx={{ mt: { xs: 0, lg: '50px'}}}/>
      <Opaque sx={{width: 1}}>
        <Typography variant="h3"> Need help Opting Out? </Typography>
        <Typography>We made a tool to help you opt out easily.</Typography>
        <Button variant="outlined" sx={{mt: 1}} onClick={() => navigate('/optout')}>GO TO OPT OUT</Button>
      </Opaque>
      <VSeparator sx={{ mt: { xs: 0, lg: '50px'}}}/>
      <Thanks />
      <VSeparator />
    </> : null }
  </VFlex>
});
