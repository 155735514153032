import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Team from './Team.jsx';
import { sendDraw1Txns, sendCollectTxns, sendExecTxns } from '../algo';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { TableCell, TableRow } from './responsive-table.js';
import { ALGO } from '../constants.js';
import Tooltip from '../components/Tooltip.jsx';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { HFlex } from './Layout.jsx';
import { numLocaleOptions } from '../constants.js';

const drawOddsTooltip = 'The target draw odds for this CupStake.'

const collectedTooltip = 'Number of team CupStakes collected from the contract.'
const drawnTooltip = 'Number of team CupStakes drawn from the contract.'
const burnRateTooltip = 'Percentage of CupStakes burnt.'

const winValueTooltip = 'The current share of the rewards pool that this team will be redeemable for, if this team wins the World Cup.';

const runnerUpValueTooltip = 'The current share of the rewards pool that this team will be redeemable for, if this team comes second in the World Cup.';

function AllNFTs({ assets, history }) {
  const data = assets.entries()
    .sort(([_, {rank: r1, odds: o1, ...p}], [__, {rank: r2, odds: o2}]) => {
      return r1 < r2 ? -1 : 1;
    });

  const teamsDrawn = history.stats?.drawTeams ?? {};
  const teamsCollected = history.stats?.teams ?? {};

  return <Table>
    <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
      <TableRow>
        <TableCell>Team</TableCell>
        <TableCell>Rank</TableCell>
        <TableCell>Drawn<Tooltip className="float-bottom">{drawnTooltip}</Tooltip></TableCell>
        <TableCell>Collected<Tooltip className="float-bottom">{collectedTooltip}</Tooltip></TableCell>
        <TableCell>Burn Rate<Tooltip className="float-bottom">{burnRateTooltip}</Tooltip></TableCell>
        <TableCell>Win Value<Tooltip size="inherit" className="float-bottom">{winValueTooltip}</Tooltip></TableCell>
        <TableCell>Runner-up Value<Tooltip className="float-bottom">{runnerUpValueTooltip}</Tooltip></TableCell>
      </TableRow>

    </TableHead>
    <TableBody>
      { data.map(([id, {flag, name, available, odds, issued, wv, ruv, rank, drawn=0, rankLabel }]) =>
      <TableRow key={`team-${id}`}>
        <TableCell>{flag} {name}</TableCell>
        <TableCell header={<>Rank</>}>{rank} / {rankLabel}</TableCell>
        <TableCell header={<>Drawn<Tooltip>{drawnTooltip}</Tooltip></>}>{teamsDrawn[id]}</TableCell>
        <TableCell header={<>Collected<Tooltip>{collectedTooltip}</Tooltip></>}>{teamsCollected[id]}</TableCell>
        <TableCell header={<>Burn Rate<Tooltip>{burnRateTooltip}</Tooltip></>}>{(100 * (teamsDrawn[id] - teamsCollected[id]) / teamsDrawn[id]).toFixed(2)}%</TableCell>
        <TableCell header={<>Win Value<Tooltip>{winValueTooltip}</Tooltip></>}>{available && wv ? `${ALGO} ${wv.toLocaleString(undefined, numLocaleOptions)}` : 'N/A'}</TableCell>
        <TableCell header={<>Runner-up Value<Tooltip>{runnerUpValueTooltip}</Tooltip></>}>{available && ruv ? `${ALGO} ${ruv.toLocaleString(undefined, numLocaleOptions)}` : 'N/A'}</TableCell>
      </TableRow>
    ) }
    </TableBody>
    </Table>;
}

export default observer(AllNFTs);
