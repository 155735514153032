import { useState, useEffect, useCallback } from 'react';
import Zoom from '../components/Zoom.jsx';
import teams from '../teams.json';
import { algodClient } from '../algo.js';
import Button from '@mui/material/Button';
import useTimeout from '../hooks/use-timeout.js';
import Countdown from '../components/Countdown.jsx';

function Stopwatch() {
  const [last, setLast] = useState(150);
  const [countdown, setCountdown] = useState(null);

  const start = () => {
    setCountdown(<Countdown from={last} />);
    setLast(last+1);
  }

  const reset = () => {
    setCountdown();
  }

  return <div>
    <Button onClick={start}>Start</Button>
    <Button onClick={reset}>Reset</Button>
    { countdown }
  </div>;
};

export default Stopwatch;
