import { makeAutoObservable } from "mobx"
import { sendExecTxns } from '../algo';

class LocalAppState {
  drawAmount = null
  drawAmountPaid = null
  drawRound = null
  slot1 = null;
  slot2 = null;
  slot3 = null;

  constructor(newObj) {
    makeAutoObservable(this);
  }

  setState(newState) {
    for(const key of Object.keys(newState)) {
      if (!this.hasOwnProperty(key)) {
        throw new Error('LocalAppState replaceState tried to set unknown key '+key);
      }
      this[key] = newState[key];
    }
  }

  replaceState(newState) {
    this.resetState();
    this.setState(newState);
  }

  resetState() {
    for(const key of Object.keys(this)) {
      this[key] = null;
    }
  }

  getSlots() {
    return [this.slot1, this.slot2, this.slot3];
  }
}

export default LocalAppState;
