import { makeAutoObservable } from 'mobx';

export default class FSM {
  globalAppState = null;
  localAppState = null;
  localAssets = null;
  assets = null;
  isDrawing = false;

  constructor(globalAppState, localAppState, localAssets, assets, account) {
    makeAutoObservable(this);
    this.globalAppState = globalAppState;
    this.localAppState = localAppState;
    this.localAssets = localAssets;
    this.assets = assets;
    this.account = account;
    this.isDrawing = false;
  }

  setIsDrawing(value) {
    this.isDrawing = value;
  }

  getAvailableActions() {
    const { account } = this;
    // if not optin(app)
    // return [optin]
    if (!account.address) {
      return ['connect'];
    }
    if (account.optedIn === false) {
      return ['optin'];
    }
    if (!account.optedIn) {
      return [];
    }
    const actions = []
    if (this.canCollect()) {
      actions.push('collect');
    }
    if (this.canExecute()) {
      actions.push('execute');
    }
    if (this.canFreeDraw()) {
      actions.push('freedraw');
    }
    if (this.canDrawPaid(1)) {
      actions.push('draw1');
    }
    if (this.canDrawPaid(3)) {
      actions.push('draw3');
    }
    return actions;
  }

  getAvailableActionsObj() {
    return this.getAvailableActions().reduce((out, name) => (out[name]=1,out), {});
  }

  canDraw(amount=1) {
    const localApp = this.localAppState;
    if (localApp.drawAmount !== 0) {
      return false
    }
    if (amount === 1) {
      return localApp.getSlots().some(slot => slot === 0);
    } else if (amount === 3) {
      return localApp.getSlots().every(slot => slot === 0);
    } else {
      throw new Error('Invalid draw amount '+amount);
    }
  }

  canDrawPaid(amount) {
    return this.canDraw(amount) && !this.hasFreeToken();
  }

  hasFreeToken() {
    const free = this.localAssets.get('free');
    return free?.amount;
  }

  canFreeDraw() {
    const freeBalance = this.localAssets.get('free');
    return this.canDraw(1) && this.hasFreeToken();
  }

  canCollect() {
    const slots = this.localAppState.getSlots();
    return slots.some(slot => slot  > 0);
  }

  canExecute() {
    return this.localAppState.drawAmount > 0;
  }

  disconnect() {
    this.account.disconnect();
    this.localAppState.resetState();
    this.localAssets.resetState();
  }
}
