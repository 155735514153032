import { ALGO } from './constants.js';

export const snakeCaseToCamel = (str) => str.replace(/_./g, s => s[1].toUpperCase())

export const convertObjectSnakeCaseToCamelCase = (obj) => Object.entries(obj).reduce((obj, [key, value]) => {
  obj[snakeCaseToCamel(key)] = value
  return obj
}, {});

export const sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

export function shorten(str, len = 8, showEnd=true) {
  const end = len - 1
  if (str?.length > 12) {
    return str.slice(0, end)+".."+(showEnd ? str.slice(-end) : "");
  }
}

function chunk(elems, num=20) {
  return elems.reduce((out, cur) => {
    let last = out[out.length - 1];
    if (last.length == num) {
      out.push([]);
      last = out[out.length -1];
    }
    last.push(cur);
    return out;
  }, [[]]);
}

const NFDCache = {};

// cachedLookupNFD
//  NFDCache -> promise || result
//  addresses -> NFDCache -> [existing, new]
//  if [new] -> NFDCache += promise
//  await Promise.all(values(NFDCache[addresses]))

export async function lookupNFD(addresses) {
  addresses = Array.isArray(addresses) ? addresses : [addresses];
  const New = []
  for(const address of addresses) {
    if (!NFDCache[address]) {
      New.push(address);
    }
  }
  if (New.length) {
    const asyncRes = _lookupNFD(New);
    for(const N of New) {
      NFDCache[N] = asyncRes.then((data) => {
        return data[N];
      });
    }
  }
  const resultsE = Object.entries(NFDCache).filter(([key]) => addresses.includes(key));
  const results = {};
  for(const [resKey, resValue] of resultsE) {
    NFDCache[resKey] = results[resKey] = await resValue;
  }
  return results;
}

async function _lookupNFD(address) {
  // TODO cache not existing
  // TODO debounce / join requests
  let addresses = Array.isArray(address) ? address : [address];
  const results = {};
  const chunks = chunk(addresses, 20);
  for(const chunk of chunks) {
    if (!chunk.length)
      continue;
    const query = chunk.join('&address=');
    // console.log("Querying", ...chunk);
    const url = `https://api.nf.domains/nfd/v2/address?address=${query}&limit=1&view=thumbnail`;
    try {
      const resp = await fetch(url);
      const json = await resp.json();
      for(const [addr, entries] of Object.entries(json)) {
        for(const { name, caAlgo } of entries) {
          if (caAlgo.includes(addr)) {
            results[addr] = name;
          }
        }
      }
    } catch(e) {
      console.log('NFDomains lookup', e);
      continue;
    }
  }
  return results;
}

export function uint8ToBase64(arr) {
  if (!arr?.length)
    return ''
  return btoa(new Array(...arr).map(s => String.fromCharCode(s)).join(""));
}

// window.nfd = lookupNFD;

// all_addrs = [...new Set([...JSON.parse(localStorage.getItem('indexer')).data.map(({address: a}) => a)])]
//
export function uppercaseFirst(str) {
  if (!str)
    return str;
  if (str === "myalgo")
    return "MyAlgo";
  if (str === "algosigner")
    return "AlgoSigner";
  return str[0].toUpperCase() + str.slice(1);
}

export function objDiff(a={}, b={}, nn=true) {
  const diff = {};
  for(const [aKey, aValue] of Object.entries(a)) {
    const bValue = b[aKey];
    if (bValue !== aValue) {
      if (nn) {
        if (bValue)
          diff[aKey] = bValue;
      } else {
        diff[aKey] = bValue;
      }
    }
  }
  return diff;
}

export function printALGO(val) {
  if (typeof val !== "number")
    return val;
  return `${ALGO}${val.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
}
