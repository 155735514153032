import { useState, useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import { Thanks, Left, Link, HFlex, VFlex, VSeparator, Opaque } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import Wallet from '../components/Wallets.jsx';
import { refreshNFTBalances, sendOptoutAssets, sendDiscard, clearoutApp } from '../algo.js';
import redeemConfig from '../redeem.json';

const winners = [redeemConfig.winner_asa_id, redeemConfig.runner_up_asa_id];

function Optout({ account, fsm, localAssets, assets, }) {
  if (!account.address) {
    return <>
      <VSeparator />
        <Opaque>
        <Typography align="center" sx={{ mb: 3 }} variant="h2">1/ Connect wallet</Typography>
        <Wallet account={account} fsm={fsm} />
      </Opaque>
      <VSeparator />
    </>;
  }

  const [wonHeld, have, notHave] = localAssets.entries().reduce((out, [id, { amount, opted_in }]) => {
    const [wonHeld, have, notHave] = out;
    if (winners.includes(Number(id)) && amount) {
      wonHeld.push(id)
      return out;
    }
    if (opted_in && amount) {
      have.push(id)
    } else if (opted_in && !amount) {
      notHave.push(id);
    }
    return out;
  }, [[],[],[]]);

  const discard = async (aids, setAids) => {
    let total = 0;
    const pairs = aids.map((aid) => {
      const { amount } = localAssets.get(aid);
      total += amount;
      return [aid, amount];
    });
    const confirmed = window.confirm(`Are you sure you want to permanently discard ${total} total CupStakes from ${pairs.length} teams?`);
    if (confirmed) {
      try {
        await sendDiscard(pairs);
        setAids([]);
        await refreshNFTBalances();
      } catch(e) {
      }
    }
  }

  const optout = async (aids, setAids) => {
    try {
      await sendOptoutAssets(aids);
      setAids([]);
      await refreshNFTBalances();
    } catch(e) {
      console.error(e);
    }
  }

  return <><VSeparator />
    <Opaque sx={{ pb: 2, width: 1 }}>
      <Typography align="center" variant="h2">Opt-out</Typography>
    </Opaque>
    <VSeparator />
      { wonHeld.length ? <><Opaque sx={{ width: 1 }}>
      <Typography align="center" variant="h3">Redeemable CupStakes!</Typography>
        <Typography>Head over to the <Link href="/redeem">Redeem</Link> page to get paid for your Winner CupStakes:</Typography>
        {wonHeld.map(id => { return localAssets.get(id).amount + 'x ' + assets.get(id).name; }).join(' and ')}
      </Opaque><VSeparator /></> : null }

      { have.length ?  <><Opaque sx={{ width: 1 }}>
        <Typography sx={{mt: 2 }} align="center" variant="h3">Discard Unwanted CupStakes</Typography>
        <Typography>Want to get rid of your disqualified CupStakes quickly?</Typography>
        <Typography>Send them back to meet their creator (wallet!)</Typography>
        <TeamSelector actionName="discard" ids={have} localAssets={localAssets} assets={assets}
          action={(selected, setSelected) => {
            return <Button variant="outlined" onClick={() => discard(selected, setSelected)} disabled={!selected.length}>DISCARD{selected.length ? ` (${selected.length} TEAM${selected.length > 1 ? 'S':''})` : ''}</Button>
          }}
        /></Opaque><VSeparator /></> : null }

      { notHave.length ? <><Opaque sx={{ width: 1 }}>
        <Typography sx={{mt: 2 }} align="center" variant="h3">Opt out of CupStake Assets</Typography>
        <Typography>Clear out some minimum balance by opting out of assets you aren't holding.</Typography>
        <TeamSelector actionName="opt out" ids={notHave} localAssets={localAssets} assets={assets}
        action={(selected, setSelected) => {
          return <Button variant="outlined" onClick={() => optout(selected, setSelected)} disabled={!selected.length}>OPT OUT{selected.length ? ` (${selected.length} ASSET${selected.length > 1 ? 'S':''})` : ''}</Button>
        }}
        /></Opaque><VSeparator /></> : null }

      { !account.optedIn && !notHave.length && !have.length ? 
        <>
          <Opaque sx={{width: 1}}>
            <Typography sx={{mb: 1}} align="center" variant="h3">All Opted Out!</Typography>
            <Typography>You are opted out of the CupStakes Application and any CupStakes assets you aren't using!</Typography>
          </Opaque>
          <VSeparator />
      </> : null }

      { account.optedIn ? 
        <>
          <Opaque sx={{width: 1}}>
            <Typography sx={{mb: 1}} align="center" variant="h3">Application Opt-out</Typography>
            <Button onClick={clearoutApp} variant="outlined">OPT OUT (APPLICATION)</Button>
          </Opaque>
          <VSeparator />
      </> : null }

    <Thanks />

    <VSeparator />
  </>
}

const dislimit = 16;

const TeamSelector = observer(({ ids, assets, localAssets, action, actionName }) => {
  const [selected, setSelected] = useState([]);

  const select = (id) => () =>
    setSelected(selected => {
      if (selected.includes(id))
        return selected.filter(_id => _id !== id)
      if (selected.length >= dislimit) {
        alert(`You can only ${actionName} up to 16 teams at a time.`);
        return selected;
      }

      return [...selected, id];
    });

  const allSelected = ids.every(id => selected.includes(id));

  const selectAll = () =>
    setSelected(selected => {
      if (allSelected)
        return []
      else {
        if (ids.length > dislimit) {
          alert(`You can only ${actionName} up to 16 teams at a time.`);
          return ids.slice(0, dislimit);
        }
        return ids;
      }
    });

  return <>
    <Left>
      <FormGroup>
      { ids.map(id => {
        const { amount } = localAssets.get(id) ?? { };
        const { name } = assets.get(id) ?? { name: 'Free Draw'};
        const label =  `${amount}x ${name}`;
        return <FormControlLabel control={<Checkbox checked={selected.includes(id)} onClick={select(id)} />} label={label} />
      }) }
        <FormControlLabel control={<Checkbox checked={allSelected} onClick={selectAll} />} label={allSelected ? 'Deselect all' : 'Select all'} />
      </FormGroup>
    </Left>
    <HFlex>{action(selected, setSelected)}</HFlex>
  </>;
});

export default observer(Optout);
