import algosdk from 'algosdk'
import savedContract from './contract.json';
import redeemContract from './redeem-contract.json';
import { uint8ToBase64, } from './utils.js';

const clientMethods = [
  'draw',
  'free_draw',
  'burn_draw',
  'burn_draw2',
  'burn_draw3',
  'draw3',
  'exec_draw',
  'collect',
  'update_state_int',
];

export const contractMethodNames = {}
export const contractMethodReverse = {}

const contract = new algosdk.ABIContract(savedContract);
for(const method of contract.methods) {
  const { name } = method;
  if (clientMethods.includes(name)) {
    const selector = method.getSelector();
    if (!selector?.length) {
      continue;
    }
    contractMethodNames[name] = selector;
    const b64 = uint8ToBase64(selector);
    contractMethodReverse[b64] = name;
  }
}

const rcontract = new algosdk.ABIContract(redeemContract);
export const redeemSelector = rcontract.methods.find(({name}) => name === 'redeem').getSelector();

export const ALGO = 'Ⱥ';

export const numLocaleOptions = { maximumFractionDigits: 2 };

export const numLocaleOptionsFull = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

export const afterRoyalT = 1670598066000;

export const oxxay = 'CUPSTAKEOXXAYC3AOAK7QG3A6776Z4TKLOGFYN6CKMR33BT6QLFBEPJY4U';

export const voteAddress = 'CUPSLAX2HYTRZFS3ADBUVO75OKZUWUWRQGS2OPQL4GMLMA27Z3ITOAVOTE';

export const govStart = 1670895136;
export const govEnd = 1671375600;
