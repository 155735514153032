import { useState, useEffect } from 'react';
import useAlgodRound from '../hooks/use-algod-round.js';
import { observer } from 'mobx-react-lite';
import notifications from '../notifications.js';
import { refreshBalances } from '../algo.js';

export default observer(function({ algorand }) {
  const [round, fail] = useAlgodRound();
  const [algoFail, setAlgoFail] = useState(false);

  useEffect(() => {
    if (fail > 1) {
      if (!algoFail) {
        notifications.error(<>Internet connectivity issue detected.<br />Check your connection.</>, 0, 'internet');
      }
    } else if (fail === 0) {
      if (algoFail) {
        notifications.closeSnackbar('internet');
        notifications.success(<>Internet connectivity restored 👍</>, 4_000, 'internet');
        setTimeout(() => refreshBalances(), 2_000);
      }
    }
    if (algoFail !== (fail > 1)) {
      setAlgoFail(fail>1);
    }
  }, [fail, algoFail]);

  useEffect(() => {
    algorand.setRound(round);
  }, [round]);

  return <></>;
});
