import algosdk from 'algosdk';
import constants from '../constants.json';
import { govStart, govEnd, oxxay, contractMethodNames, contractMethodReverse, afterRoyalT, voteAddress, } from '../constants.js';
import { sleep, convertObjectSnakeCaseToCamelCase } from '../utils.js';
import indexerConfig from '../indexer.json';
import get from 'lodash/get';
import { UintArrayToHex, UintEquals, convertStringKey, convertIntKey, convertIntKey2 } from './utils.js';
import { objDiff } from '../utils.js';
import teamNFTIDs from '../team_nft_ids.json';

const NFTIDs = Object.keys(teamNFTIDs).map(n => Number(n));

const { appId, storageAppId, rewardsAddress, genesisRound, oracleAppId, network, } = convertObjectSnakeCaseToCamelCase(constants);

const server = network === "testnet" ? "https://testnet-idx.algonode.cloud" : "https://mainnet-idx.algonode.cloud";
const port = 443;
const indexer = new algosdk.Indexer("", server, port);

const MAX_ATTEMPTS = 4;

const SLOT_NAMES = ['slot1', 'slot2', 'slot3'];
const BURN_METHODS = ['burn_draw', 'burn_draw2', 'burn_draw3'];
const DRAW_METHODS = ['draw', 'free_draw', 'draw3'];

const queryIndexer = async (queryObj, limit = 5000, nextToken, attempts = 1) => {
  if (nextToken) {
    queryObj.nextToken(nextToken);
  }
  if (!queryObj.query.limit) {
    queryObj.limit(limit);
  }
  // console.log("index query", JSON.stringify(queryObj.query), `nT=${nextToken}, attempts=${attempts}`);
  const initQueryObj = { ...queryObj };
  try {
    const res = await queryObj.do();
    if (res['next-token']) {
      await sleep(500);
      if (attempts > 3)
        limit *= 2;
      return [...res.transactions, ...await queryIndexer(queryObj, limit, res['next-token'], 1)];
    }
    return res.transactions;
  } catch(e) {
    const message = e.response?.body?.message ?? e.response?.body ?? e.message;
    console.error(`Error while querying indexer, attempt ${attempts}: ${e.message}`, e);
    if (attempts < MAX_ATTEMPTS) {
      if (attempts > 2) {
        limit /= 2;
      }
      attempts++;
      queryObj.limit(limit);
      await sleep(Math.pow(attempts, 2) * 500);
      return queryIndexer(queryObj, limit, nextToken, attempts);
    } else {
      console.error(`Too many failures querying indexer`);
      throw e;
    }
  }
}

export const globalHistory = async(since = genesisRound, addressStates = {}) => {
  const query = indexer.searchForTransactions()
    .applicationID(appId)
    .minRound(since)
    // .maxRound(25061156);
  const results = await queryIndexer(query);
  return [results.map(r => extractLocalStateDelta(r, addressStates)).filter(Boolean).reverse(), addressStates];
}

export const spendStats = (txns) => {
  let spends = {
    all: 0,
  };
  for(const txn of txns) {
    if (!txn)
      continue;
    if (txn.d?.draw_amount_paid) {
      const amt = txn.d.draw_amount_paid / 1_000_000;
      spends.all += amt;
      spends[txn.address] = (spends[txn.address] ?? 0) + amt;
    }
  }
  return spends;
}

export const quickStats = (txns) => {
  let teams = {
    // [asa_id]: num_draws
  };
  let draws = 0;
  let spend = 0;
  for(const txn of txns) {
    if (!txn)
      continue;
    if (txn.method === "collect") {
      for(const team of Object.values(txn.p)) {
        teams[team] = teams[team] ?? 0;
        teams[team]++;
      }
    }
    draws += getMethodNumber(txn.method);
  }
  return { teams, draws, address: txns[0].address };
}

export const txnStats = (txns) => {
  let collTeams = {
    // [asa_id]: num_draws
  };
  let drawTeams = {
    // [asa_id]: num_draws
  };
  const methods = {
    // [method]: n
  };
  let spend = 0;
  let players = new Set();
  for(const txn of txns) {
    if (!txn)
      continue;
    if (txn.method) {
      methods[txn.method] = (methods[txn.method] ?? 0);
      methods[txn.method] += 1;
    }
    if (txn.method === "exec_draw") {
      for(const slot of SLOT_NAMES) {
        const team = txn.d[slot];
        if (team) {
          drawTeams[team] = (drawTeams[team] ?? 0) + 1
        }
      }
    }
    if (txn.method === "collect") {
      for(const team of Object.values(txn.p)) {
        collTeams[team] = collTeams[team] ?? 0;
        collTeams[team]++;
      }
    }
    if (txn.d['draw_amount_paid']) {
      spend += txn.d['draw_amount_paid'];
    }
    players.add(txn.address);
  }
  const stats = burnRate(methods);
  stats.spend = spend / 1_000_000;
  return { teams: collTeams, drawTeams, methods, stats, players: players.size }
}

function teamRates(teams) {
  const total = Object.values(teams).reduce((out, cur) => out+cur, 0);
  const rates = {};
  for(const [id, num] of Object.entries(teams)) {
    rates[id] = num / total * 100;
  }
  return rates;
}

function getMethodNumber(name) {
  switch(name) {
    case 'burn_draw':
    case 'draw':
    case 'free_draw': return 1;

    case 'burn_draw2': return 2;

    case 'burn_draw3':
    case 'draw3': return 3;
  }
  return 0;
}

function burnRate(stats) {
  let burns = 0;
  let draws = 0;
  for(const burn of BURN_METHODS) {
    const n = getMethodNumber(burn);
    burns += n * (stats[burn] ?? 0);
  }
  for(const draw of DRAW_METHODS) {
    draws += getMethodNumber(draw) * (stats[draw] ?? 0);
  }
  const total = burns + draws;
  return { total, burns, draws, rate: (burns/total*100).toFixed(0) };
}

export const allAccountsHistory = async (txns) => {
  txns = txns ?? await globalHistory();
  const myTxns = txns.reduce((out, txn) => {
    if (!txn) return out;
    const { address } = txn;
    out[address] = out[address] || [];
    out[address].push(txn);
    return out;
  }, {});
}

export const filterAccount = (address, txns) => {
  const myTxns = txns.filter(txn => txn?.address === address);
  return myTxns;
}

function extractLocalStateDelta(txn, addressStates) {
  const delta = get(txn, 'local-state-delta.0');
  if (!delta)
    return;
  try {
    const method = getMethodName(txn);
    const { id, address } = txn;
    const daddr = delta.address;
    const prevState = addressStates[daddr] ?? {};
    const out = {
      id: txn.id,
      method,
      rT: txn["round-time"],
      r: txn["confirmed-round"],
      address: daddr,
      d: delta.delta.reduce((out, {key, value: { uint }}) => {
        out[formatKey(key)] = uint;
        return out;
      }, {}),
    };
    out.p = objDiff(out.d, prevState);
    // if (method === 'exec_draw') {
    //   const oracleItxns = txn['inner-txns'].filter(itxn => {
    //     return get(itxn, 'application-transaction.application-id') == 947957720
    //   });
    //   out.randomVals = oracleItxns.map(itxn => {
    //     const ret = itxn.logs[itxn.logs.length - 1];
    //     const header = ret.slice(0, 3);
    //     if (ret.slice(0, 4) === "FR98") {
    //       const res = ret.slice(-8);
    //       // console.log(id, res, parseInt(Buffer.from(res, 'base64').toString('hex'), 16) % 2**20);
    //       return res;
    //     }
    //   });
    // }
    addressStates[daddr] = { ...prevState, ...out.d };
    return out;
  } catch(e) {
    console.error("Error parsing txn", txn.id, e.message);
  }
}

function getMethodName(txn) {
  const methodNameEncoded = get(txn, 'application-transaction.application-args.0');
  if (contractMethodReverse[methodNameEncoded])
    return contractMethodReverse[methodNameEncoded];
  const onCompletion = get(txn, 'application-transaction.on-completion');
  if (['optin', 'closeout', 'clearout'].includes(onCompletion)) {
    return onCompletion;
  }
}

function formatKey(key) {
  const b64decKey = atob(key);
  const stringKey = /[A-Za-z0-9]/.test(b64decKey);
  if (!stringKey) {
    throw new Error('TODO');
  }
  return stringKey ? b64decKey : parseInt(key.toString('hex'), 16);
}

export const roundForRoyalT = 25366650;

export const getVoteTxns = async () => {
  const query = indexer.searchForTransactions()
    .address(voteAddress)
    .addressRole('receiver')
    .minRound(roundForRoyalT)

  const results = await queryIndexer(query);

  return results
    .reduce((out, txn) => {
      const { sender, note, round, } = txn;
      const txtNote = Buffer.from(txn['note'], 'base64').toString();
      if (!txtNote.startsWith('cupstakes/v1:j')) {
        return out;
      }
      if (txn["round-time"] < govStart || txn["round-time"] > govEnd) {
        return out;
      }
      try {
        const vote = JSON.parse(txtNote.slice(14));
        if (!Array.isArray(vote))
          throw new Error('Not array');
        out.push([sender, vote, txn["round-time"], txn.id]);
      } catch(e) {
        out.push([sender, false, txn["round-time"], txn.id]);
        console.log('Invalid vote', sender, txtNote);
      }
      return out;
    }, []);
}

export const freeDrawOffsets = async () => {
  const query = indexer.searchForTransactions()
    .address(oxxay)
    .addressRole('sender')
    .minRound(genesisRound)
    .maxRound(25685731);

  const results = await queryIndexer(query);

  return results
    .filter(tx => {
      return tx["payment-transaction"] && tx["payment-transaction"].receiver === rewardsAddress;
    })
    .map(tx => {
      const { id, "round-time": rT, "payment-transaction": { amount } } = tx;
      return { id, amount, rT };
    });
}

export const rewardsBoost = async () => {
  const query = indexer.searchForTransactions()
    .address(oxxay)
    .addressRole('receiver')
    .minRound(roundForRoyalT)

  const results = await queryIndexer(query);

  return results
    .filter(tx => {
      return tx["round-time"] > (afterRoyalT / 1000);
    })
    .map(tx => {
      const itxns = tx['inner-txns'];
      if (!itxns)
        return [];
      return itxns.filter(tx => tx['payment-transaction'] && tx['payment-transaction']['receiver'] == oxxay);
    })
    .reduce((sum, cur) => sum + cur.reduce((sum, {"payment-transaction": {amount, ...rp}, ...rest}) => sum+(amount??0), 0), 0);
}

export const oxxayReceived = async() => {
  const query = indexer.searchForTransactions()
    .address(oxxay)
    .addressRole('receiver')
    .minRound(roundForRoyalT)

  const results = await queryIndexer(query);

  const royalties = results
    .filter(tx => {
      return tx["round-time"] < (afterRoyalT / 1000);
    })
    .map(tx => {
    const itxns = tx['inner-txns'];
    if (!itxns)
      return
    return itxns.filter(tx => tx['payment-transaction'] && tx['payment-transaction']['receiver'] == oxxay);
  }).filter(Boolean)
    .reduce((sum, cur) => sum + cur.reduce((sum, {"payment-transaction": {amount}}) => sum+amount, 0), 0);

  const homesends = results.reduce((out, txn) => {
    const aid = get(txn, 'asset-transfer-transaction.asset-id');
    if (aid && NFTIDs.includes(aid)) {
      const amount = get(txn, 'asset-transfer-transaction.amount');
      const { sender } = txn;
      out[sender] = out[sender] ?? {};
      out[sender][aid] = out[sender][aid] ?? 0;
      out[sender][aid]+=amount;
    }
    return out;
  }, {});

  for(const [address, aids] of Object.entries(homesends)) {
    for(const [aid, q] of Object.entries(aids)) {
      const b = Math.floor(q/3);
      aids[aid] = b;
    }
  }
  return { royalties, homesends };
};

const ourAppIds = [appId, storageAppId];

export const appParamUpdates = async() => {
  const query = indexer.searchForTransactions()
    .address(oxxay)
    .addressRole('sender')
    .minRound(genesisRound)

  const results = await queryIndexer(query);

  // const gTxns = results.reduce((tp, txn) => {
  //   const {"tx-type": txType } = txn;
  //   tp[txType] = tp[txType] ?? [];
  //   tp[txType].push(txn);
  //   return tp;
  // }, {});

  const roundTimes = {[genesisRound]: 1668956273};
  const appTxns = results.reduce((apps, txn) => {
    const { "confirmed-round": round, "round-time": rt, "tx-type": txType } = txn;
    if (txType !== "appl")
      return apps;
    const txnAppId = txn["application-transaction"]["application-id"];
    if (!ourAppIds.includes(txnAppId))
      return apps;
    roundTimes[round] = rt;
    apps[txnAppId][round] = apps[txnAppId][round] ?? [];
    apps[txnAppId][round].push(txn);
    return apps;
  }, {[appId]: {}, [storageAppId]: {}});

  const oddsUpdates = parseStorageStateUpdatesPerRound(appTxns[storageAppId]);

  for(const [round, odds] of Object.entries(oddsUpdates)) {
    if (!odds[64]) {
      console.log('ignoring', round);
      delete oddsUpdates[round];
      continue;
    }
    let prev=0;
    const finalOdds = [];
    for(let i=1; i<64; i+=2) {
      const team = odds[i];
      const cumOdds = odds[i+1];
      finalOdds.push([team, cumOdds, (cumOdds - prev) / 2**20]);
      prev = cumOdds;
    }
    oddsUpdates[round] = { odds: finalOdds, gid: odds.gid };
  }

  const mainAppUpdates = parseMainStateUpdatesPerRound(appTxns[appId]);
  mainAppUpdates[genesisRound] = {
    id: 'GBKLXUATTWXLLW6A62WCRC65OVX6RHIG3B5SGZS4EOH7PDJE7QUA',
    drawAppId: '951618646',
    storageAppId: '951618464',
    ticket: 2200000,
    oracleAppId: 947957720,
    burnTicket: 1716000,
  };

  return { mainAppUpdates, oddsUpdates, roundTimes }
};

window.ox = appParamUpdates;

function parseMainStateUpdatesPerRound(roundTxns) {
  return Object.entries(roundTxns)
    .reduce((oddsUpdates, [round, txns]) => {
      for(const txn of txns) {
        const { id } = txn;
        const { "application-args": [firstArg, ...restArgs] } = txn["application-transaction"];
        // console.log(txn.id, round, firstArg, ...restArgs);
        if (firstArg !== "st+Tiw==") {
          continue;
        }
        oddsUpdates[round] = oddsUpdates[round] ?? {};
        const intRestArgs = restArgs.slice(0, 14).map((r, i) => {
          if (i % 2 === 0) {
            if (convertIntKey(r)>0)
              return convertStringKey(r.slice(0)).slice(2);
          } else {
            return convertIntKey2(r);
          }
        });
        for(let i=0; i<intRestArgs.length; i+=2) {
          const key = intRestArgs[i];
          const value = intRestArgs[i+1];
          if (key)
            oddsUpdates[round][key] = value;
        }
        oddsUpdates[round].id = id;
      }
      return oddsUpdates;
    }, {});
}

function parseStorageStateUpdatesPerRound(roundTxns) {
  return Object.entries(roundTxns)
    .reduce((oddsUpdates, [round, txns]) => {
      oddsUpdates[round] = {};
      for(const txn of txns) {
        const { "application-args": [firstArg, ...restArgs] } = txn["application-transaction"];
        // console.log(txn.id, round, firstArg, ...restArgs);
        if (firstArg !== "st+Tiw==") {

          continue;
        }
        if (!oddsUpdates[round].gid)
          oddsUpdates[round].gid = txn.group;
        const intRestArgs = restArgs.slice(0, 14).map(r => convertIntKey2(r));
        if (restArgs.length === 15) {
          const la = restArgs[restArgs.length - 1];
          intRestArgs.push(convertIntKey2(la.slice(12)));
          intRestArgs.push(convertIntKey2(la.slice(0, 14)));
        }
        for(let i=0; i<intRestArgs.length; i+=2) {
          const key = intRestArgs[i];
          const value = intRestArgs[i+1];
          oddsUpdates[round][key] = value;
        }
      }
      return oddsUpdates;
    }, {});
}
