import notifications from '../notifications.js';
import { VFlex, HFlex } from '../components/Layout.jsx';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const SIGN_TX = 'signTx';
const SIGN_TX_ERROR = 'signTxError';
const SHOW_PERA_TROUBLESHOOTING = 'SHOW_PERA_TROUBLESHOOTING';

function peraFlow2() {
  notifications.info(<VFlex sx={{alignItems: 'flex-start'}}>
    <Typography>You can try the following:</Typography>
    <Typography>- Close the Pera app and open it again, or</Typography>
    <Typography>- Disconnect and Reconnect, or</Typography>
    <Typography>- Visit the Pera Troubleshooting page:</Typography>
    <HFlex sx={{justifyContent: 'flex-start', width: 1, mt: 1}}>
      <Button sx={{mr: 1}} size="small" variant="outlined" onClick={() => window.open('https://support.perawallet.app/en/article/resolving-walletconnect-issues-1tolptm/')}>PERA TROUBLESHOOTING</Button>
    </HFlex>
  </VFlex>, 15_000, SIGN_TX_ERROR);
}

function peraFlow() {
  notifications.info(<VFlex>
    <>Problems signing with Pera?</>
    <HFlex sx={{justifyContent: 'flex-start', width: 1, mt: 1}}>
      <Button sx={{mr: 1}} size="small" variant="outlined" onClick={peraFlow2}>YES</Button>
      <Button size="small" variant="outlined" onClick={() => notifications.closeSnackbar(SIGN_TX_ERROR)}>NO</Button>
    </HFlex>
  </VFlex>, 15_000, SIGN_TX_ERROR);
}

export async function signTransactionsWithWallet (account, txns) {
  if (!Array.isArray(txns)) {
    txns = [txns];
  }
  notifications.closeSnackbar(SIGN_TX_ERROR);
  if (account.wallet === "pera") {
    let _reject;
    const _cancel = () => _reject(new Error(SHOW_PERA_TROUBLESHOOTING));
    const cancel = new Promise((resolve, reject) => _reject = reject);
    const txnGroup = txns.map(txn => ({txn}));
    notifications.info(<VFlex>
      <>Please sign the transaction on Pera</>
      <Button sx={{mt: 1, alignSelf: 'flex-start'}} onClick={_cancel} size="small" variant="outlined">CANCEL</Button>
    </VFlex>, false, SIGN_TX);
      try {
        return await Promise.race([
          account.walletObject.signTransaction([txnGroup]),
          cancel
        ]);
      } catch (e) {
        if (e.message === SHOW_PERA_TROUBLESHOOTING) {
          return peraFlow();
        } else {
          notifications.error(e.message, false, SIGN_TX_ERROR);
        }
        return;
      } finally {
        notifications.closeSnackbar(SIGN_TX);
      }
  } else if (account.wallet === "myalgo") {
      const myAlgoConnect = account.walletObject;
      notifications.info('Please sign the transaction on MyAlgo', false, SIGN_TX);
      try {
        return (await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()))).map(txn => txn.blob);
      } catch(e) {
          notifications.error(e.message, false, SIGN_TX_ERROR);
        return;
      } finally {
        notifications.closeSnackbar(SIGN_TX);
      }
  } else if (account.wallet === "algosigner") {
      const AlgoSigner = account.walletObject;
      try {
        const bTxns = txns.map(txn => ({ txn: AlgoSigner.encoding.msgpackToBase64(txn.toByte()) }))
        notifications.info('Please sign the transaction on AlgoSigner', false, SIGN_TX);
        const signed = (await AlgoSigner.signTxn(bTxns));
        if (signed.length && signed[0].blob) {
          return signed.map(txn => AlgoSigner.encoding.base64ToMsgpack(txn.blob));
        }
        throw new Error('Could not receive signed txn from AlgoSigner');
      } catch(e) {
        notifications.error(e.message, false, SIGN_TX_ERROR);
        return;
      } finally {
        notifications.closeSnackbar(SIGN_TX);
      }
  } else {
    notifications.error(`No wallet connected`);
    return;
  }
}

export const signTransactionWithWallet = signTransactionsWithWallet;
